<template>
  <fieldset>
    <div class="columns is-vcentered">
      <div
        v-if="contactPhoto"
        class="column is-narrow"
      >
        <figure
          class="image is-128x128"
          style="position: relative;"
        >
          <img
            style="width: 128px; height: 128px;"
            width="128"
            height="128"
            class="is-rounded"
            :src="contactPhoto"
            :alt="firstName"
          >
          <span
            style="position: absolute;top: 0;right: 0;"
            @click="removeContactPhoto"
          >
            <b-icon
              icon="close"
              class="is-medium"
              custom-size="fa-lg"
              type="is-danger"
            />
          </span>
        </figure>
      </div>

      <div class="column">
        <b-field class="file is-info">
          <b-upload
            v-model="contactPhoto"
            class="file-label"
          >
            <span class="file-cta">
              <b-icon
                class="file-icon"
                icon="upload"
              />
              <span class="file-label">upload photo</span>
            </span>
          </b-upload>
        </b-field>
      </div>
    </div>

    <b-field
      horizontal
      label="Contact Owner"
      label-for="contactOwner"
      class="is-required"
    >
      <b-autocomplete
        id="contactOwner"
        v-model="owner"
        icon-right="search"
        field="fullName"
        :data="usersSearchResults"
        @typing="getUsersAsyncData"
        @select="(option) => (selectedUser = option)"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-content">
              {{ props.option.fullName.trim() }}
              <br>
              <small>
                email: {{ props.option.email }} <br>
                Phone: {{ props.option.phoneCell }}
              </small>
            </div>
          </div>
        </template>

        <template #empty>
          No results for {{ owner }}
        </template>
      </b-autocomplete>
    </b-field>

    <b-field
      horizontal
      label="Title"
      label-for="title"
    >
      <b-input
        id="title"
        v-model="title"
      />
    </b-field>

    <b-field
      horizontal
      label="Prefix"
      label-for="prefix"
    >
      <b-input
        id="prefix"
        v-model="prefix"
      />
    </b-field>

    <b-field>
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="first name"
      >
        <b-field
          horizontal
          label="First Name"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-autocomplete
            v-model="firstName"
            :data="contactsSearchResults"
            field="firstName"
            icon-right="search"
            @typing="getContactsAsyncData"
            @select="(option) => (selectedContact = option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <img
                    width="32"
                    :src="props?.option?.photo ? props.option.photo : defaultImage"
                    :alt="props?.option?.contactName"
                  >
                </div>
                <div class="media-content">
                  {{ props?.option?.contactName?.trim() }}
                  <br>
                  <small>
                    Phone: {{ props?.option?.phone }}, Email:
                    <b>{{ props?.option?.email }}</b>
                  </small>
                </div>
              </div>
            </template>

            <template #empty>
              No results for {{ firstName }}
            </template>
          </b-autocomplete>
        </b-field>
      </validation-provider>
    </b-field>

    <b-field>
      <validation-provider
        v-slot="{ errors }"
        rules=""
        name="middle name"
      >
        <b-field
          horizontal
          label="Middle Name"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-autocomplete
            v-model="middleName"
            :data="contactsSearchResults"
            field="middleName"
            icon-right="search"
            @typing="getContactsAsyncData"
            @select="(option) => (selectedContact = option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <img
                    width="32"
                    :src="props?.option?.photo ? props.option.photo : defaultImage"
                    :alt="props?.option?.contactName"
                  >
                </div>
                <div class="media-content">
                  {{ props?.option?.contactName?.trim() }}
                  <br>
                  <small>
                    Phone: {{ props?.option?.phone }}, Email:
                    <b>{{ props?.option?.email }}</b>
                  </small>
                </div>
              </div>
            </template>

            <template #empty>
              No results for {{ middleName }}
            </template>
          </b-autocomplete>
        </b-field>
      </validation-provider>
    </b-field>

    <b-field>
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="last name"
      >
        <b-field
          horizontal
          label="Last Name"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-autocomplete
            v-model="lastName"
            :data="contactsSearchResults"
            field="lastName"
            icon-right="search"
            @typing="getContactsAsyncData"
            @select="(option) => (selectedContact = option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <img
                    width="32"
                    :src="props?.option?.photo ? props.option.photo : defaultImage"
                    :alt="props?.option?.contactName"
                  >
                </div>
                <div class="media-content">
                  {{ props?.option?.contactName?.trim() }}
                  <br>
                  <small>
                    Phone: {{ props?.option?.phone }}, Email:
                    <b>{{ props?.option?.email }}</b>
                  </small>
                </div>
              </div>
            </template>

            <template #empty>
              No results for {{ lastName }}
            </template>
          </b-autocomplete>
        </b-field>
      </validation-provider>
    </b-field>

    <b-field>
      <validation-provider
        v-slot="{ errors }"
        rules="required|email"
        name="email address"
      >
        <b-field
          horizontal
          label="Email Address"
          label-for="emailAddress"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            id="emailAddress"
            v-model="emailAddress"
          />
        </b-field>
      </validation-provider>
    </b-field>

    <b-field>
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="phone"
      >
        <b-field
          horizontal
          label="Phone"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <div class="control is-medium">
            <vue-tel-input v-model="phone" />
          </div>
        </b-field>
      </validation-provider>
    </b-field>

    <b-field>
      <validation-provider
        v-slot="{ errors }"
        name="work phone"
      >
        <b-field
          horizontal
          label="Cell"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <div class="control is-medium">
            <vue-tel-input v-model="phoneWork" />
          </div>
        </b-field>
      </validation-provider>
    </b-field>

    <b-field
      label="Address"
      horizontal
    >
      <b-field
        label="Street 1"
        label-for="street1"
        label-position="inside"
      >
        <b-input
          id="street1"
          v-model="street1"
        />
      </b-field>
    </b-field>

    <b-field horizontal>
      <b-field
        label="Street 2"
        label-for="street2"
        label-position="inside"
      >
        <b-input
          id="street2"
          v-model="street2"
        />
      </b-field>
    </b-field>

    <b-field horizontal>
      <b-field
        label="Street 3"
        label-for="street3"
        label-position="inside"
      >
        <b-input
          id="street3"
          v-model="street3"
        />
      </b-field>
    </b-field>

    <b-field
      grouped
      horizontal
    >
      <b-field
        label="City"
        label-for="city"
        label-position="inside"
      >
        <b-input
          id="city"
          v-model="city"
        />
      </b-field>

      <b-field
        label="State"
        label-for="state"
        label-position="inside"
      >
        <b-autocomplete
          id="state"
          ref="stateAutocomplete"
          v-model="state"
          field="name"
          :data="filteredStatesData"
          :open-on-focus="false"
          @select="(option) => (selected = option)"
        />
      </b-field>

      <b-field
        label="Zip"
        label-for="zip"
        label-position="inside"
      >
        <b-input
          id="zip"
          v-model="zip"
        />
      </b-field>
    </b-field>

    <b-field
      horizontal
      grouped
      group-multiline
      label="Birthday"
    >
      <b-field
        label="Month"
        label-position="inside"
        expanded
      >
        <b-select
          v-model="month"
          expanded
        >
          <option
            v-for="(mth, index) in months"
            :key="index"
            :value="mth.id"
          >
            {{ mth.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Date"
        label-position="inside"
      >
        <b-input
          v-model="date"
          type="number"
          max="31"
          min="1"
        />
      </b-field>
    </b-field>

    <b-field
      horizontal
      label="External ID"
    >
      <b-input v-model="externalId" />
    </b-field>

    <br>

    <div
      v-if="customFieldsList.length > 0"
      class="customFieldsList"
    >
      <div class="content">
        <h3 class="subtitle has-text-primary is-capitalized">
          Custom Fields
        </h3>
      </div>

      <hr class="divider">

      <validation-provider
        v-for="(field, index) in customFieldsList"
        :key="index"
        v-slot="{ errors }"
        :rules="{required: field.isRequired}"
        :name="field.label"
      >
        <b-field
          v-if="field.type === 'TEXT_INPUT'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column">
              <b-input v-model="field.value" />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'CURRENCY'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column">
              <currency-input
                v-model="field.value"
                class="input"
              />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'DROP_DOWN'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column">
              <b-select
                v-model="field.value"
                expanded
              >
                <option
                  v-for="(option, optionIndex) in field.lookups"
                  :key="optionIndex"
                  :value="option.value"
                >
                  {{ option.value }}
                </option>
              </b-select>
            </div>
            <div class="column is-narrow">
              <b-button
                aria-label="Clear Value"
                type="is-danger"
                icon-right="close"
                @click="clearValue(index)"
              />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'MULTI_SELECT'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column is-half">
              <b-taginput
                v-model="field.value"
                :data="field.lookups.map(i => i.value)"
                autocomplete
                field="value"
                type="is-info"
                :open-on-focus="true"
                placeholder="Select one or multiple"
                @typing="(val) => getCustomFieldFilteredTags(val, field)"
              />
            </div>
          </div>
        </b-field>
      </validation-provider>
    </div>
  </fieldset>
</template>

<script>
import ContactsMixin from '@/mixins/ContactsMixin';
import SearchMixin from '@/mixins/SearchMixin';

export default {
  name: 'ContactsForm',

  mixins: [ContactsMixin, SearchMixin],

  async created() {
    try {
      await this.$store.dispatch('Settings/fetchCustomFields', 'Contact');
    } catch (error) {
      console.error(JSON.stringify(error.message));
    } finally {
      this.isLoading = false;
    }
  },

};
</script>
