<template>
  <div>
    <!-- page header component -->
    <page-header>
      <template #title>
        <b-tooltip
          v-if="lead.id && lead.owners.length > 0 && checkPermissions"
          type="is-primary"
          label="Click to edit this lead"
          position="is-bottom"
        >
          <router-link
            :to="{ name: 'EditLead', params: { leadId: lead.id } }"
            class="title has-text-primary"
          >
            <template>
              <b-icon
                icon="pencil"
                type="is-info"
                size="is-medium"
              />
            </template>
            {{ lead.name }}
          </router-link>
        </b-tooltip>
        <template
          v-else
          class="title has-text-primary"
        >
          <b-icon
            icon="star"
            type="is-info"
            size="is-large"
          />
          <span class="title has-text-primary">{{ lead.name }}</span>
        </template>
      </template>

      <template
        v-if="checkPermissions"
        #actions
      >
        <div class="level-item">
          <search-bar />
        </div>

        <div
          v-if="lead.stage && lead.stage.code !== 'closed_converted'"
          class="level-item"
        >
          <b-button
            type="is-primary"
            icon-left="bullseye"
            @click="convertToOpportunity"
          >
            convert to opportunity
          </b-button>
        </div>

        <div class="level-item">
          <b-dropdown aria-role="list">
            <b-button
              v-if="lead.name"
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>

            <template v-if="hasPermissions('create_activities')">
              <b-dropdown-item
                has-link
                aria-role="menuitem"
              >
                <router-link
                  :to="{
                    name: 'AddActivity',
                    query: { leadId: lead.id, lead: lead.name },
                  }"
                >
                  Activity
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_documents')"
              aria-role="listitem"
              class="has-text-grey"
              @click="openSidebar()"
            >
              Document
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <b-breadcrumb
          size="is-small"
        >
          <b-breadcrumb-item
            tag="router-link"
            to="/profile"
          >
            Home
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/leads"
          >
            Leads
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/"
            active
          >
            {{ lead.name }}
          </b-breadcrumb-item>
        </b-breadcrumb>

        <b-tabs v-model="activeTab">
          <b-tab-item label="overview">
            <div class="columns">
              <div class="column is-three-fifths">
                <div class="content">
                  <h3 class="subtitle is-capitalized has-text-primary">
                    owners
                  </h3>
                </div>

                <div
                  v-if="lead.owners"
                  class="box has-background-light"
                >
                  <div
                    v-if="lead.owners && lead.owners.length > 0"
                    class="columns"
                  >
                    <div
                      v-for="(owner, index) in lead.owners"
                      :key="index"
                      class="column"
                    >
                      <article class="media">
                        <figure class="media-left">
                          <p class="image is-48x48">
                            <img
                              :src="owner.owner.userPhoto ? owner.owner.userPhoto : defaultImage"
                              alt="user"
                            >
                          </p>
                        </figure>

                        <div class="media-content">
                          <p class="has-text-weight-semibold has-text-grey">
                            {{ `${owner.owner.firstName} ${owner.owner.lastName}` }}
                          </p>

                          <b-tag> {{ owner.percentage }} % </b-tag>
                        </div>
                      </article>
                    </div>
                  </div>

                  <div class="columns is-mobile">
                    <div class="column is-half">
                      <b-message type="is-success">
                        <p class="heading has-text-weight-bold has-text-grey">
                          Estimated Amount
                        </p>
                        <span class="subtitle has-text-grey">
                          {{ lead.estimatedPotentials.estimatedRevenue | currency }}
                        </span>
                      </b-message>
                    </div>
                  </div>
                </div>

                <hr class="divider">

                <div class="content">
                  <h3 class="subtitle is-capitalized has-text-primary">
                    lead details
                  </h3>
                </div>

                <leads-details
                  :is-loading="isLoading"
                  :lead="lead"
                  :custom-fields-list="customFieldsList"
                />

                <hr class="divider">

                <div class="content">
                  <h3 class="subtitle is-capitalized has-text-primary">
                    company information
                  </h3>
                </div>

                <div class="columns">
                  <div class="column is-two-thirds-tablet">
                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="has-text-weight-bold is-capitalized">
                            industry
                          </p>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span
                            v-if="isLoading"
                            style="width: 120px"
                          >
                            <b-skeleton :animated="true" />
                          </span>

                          <template v-else>
                            <span
                              v-if="lead.companyInformation && lead.companyInformation.industry"
                              class="has-text-grey"
                            >
                              {{ lead.companyInformation.industry.value }}
                            </span>
                          </template>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="has-text-weight-bold is-capitalized">
                            annual revenue
                          </p>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span
                            v-if="isLoading"
                            style="width: 120px"
                          >
                            <b-skeleton :animated="true" />
                          </span>

                          <template v-else>
                            <span
                              v-if="lead.companyInformation"
                              class="has-text-grey"
                            >
                              {{ lead.companyInformation.annualRevenue }}
                            </span>
                          </template>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="has-text-weight-bold is-capitalized">
                            no. of employees
                          </p>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span
                            v-if="isLoading"
                            style="width: 120px"
                          >
                            <b-skeleton :animated="true" />
                          </span>

                          <template v-else>
                            <span
                              v-if="
                                lead.companyInformation && lead.companyInformation.noOfEmployees
                              "
                              class="has-text-grey"
                            >
                              {{ lead.companyInformation.noOfEmployees.value }}
                            </span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="divider">

                <div class="content">
                  <h3 class="subtitle is-capitalized has-text-primary has-text-weight-bold">
                    Influencers Information
                  </h3>
                </div>

                <div
                  v-if="influencerList"
                  class="columns is-multiline"
                >
                  <div
                    v-for="(influencer, key) in influencerList"
                    :key="key"
                    class="column mx-leftCol is-two-thirds-tablet"
                  >
                    <div class="level is-mobile column is-full">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="has-text-weight-bold is-capitalized">
                            Influencer Name
                          </p>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span
                            v-if="isLoading"
                            style="width: 120px"
                          >
                            <b-skeleton :animated="true" />
                          </span>

                          <router-link
                            v-else
                            :to="{
                              name: 'ViewAccount',
                              params: { accountId: influencer.account.id },
                            }"
                            class="has-text-info has-text-weight-semibold"
                          >
                            {{ influencer.account.name }}
                          </router-link>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile column is-full">
                      <div class="level-left">
                        <div class="level-item">
                          <p class="has-text-weight-bold is-capitalized">
                            Influencer Contacts
                          </p>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <div class="content">
                            <span
                              v-if="isLoading"
                              style="width: 120px"
                            >
                              <b-skeleton :animated="true" />
                            </span>
                            <template
                              v-if="influencer.contacts !== []
                                && influencer.contacts !== null"
                            >
                              <template v-for="(contact, ind) in influencer.contacts">
                                <span :key="ind">
                                  <router-link
                                    class="has-text-right
                                has-text-grey
                                is-capitalized"
                                    :to="{
                                      name: 'EditContact',
                                      params: { contactId: contact.id },
                                    }"
                                  >
                                    {{ `${contact.name}` }}
                                  </router-link>
                                  <br>
                                </span>
                              </template>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column">
                <activities-tabs @view-more="activeTab = 1" />

                <!-- TODO: Enable on dev env -->
                <div v-if="false">
                  <div class="columns">
                    <div class="column is-three-fifths">
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <p class="has-text-weight-bold is-capitalized">
                              Created Time
                            </p>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <time v-if="lead.createdAt">{{ lead.createdAt | date }}</time>
                          </div>
                        </div>
                      </div>

                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <p class="has-text-weight-bold is-capitalized">
                              Created By
                            </p>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <p v-if="lead.createdBy">
                              {{ lead.createdBy.firstName }}
                              {{ lead.createdBy.lastName }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <p class="has-text-weight-bold is-capitalized">
                              Modified Time
                            </p>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <time v-if="lead.updatedAt">{{ lead.updatedAt | date }}</time>
                          </div>
                        </div>
                      </div>

                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <p class="has-text-weight-bold is-capitalized">
                              Modified By
                            </p>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <p v-if="lead.updatedBy">
                              {{ lead.updatedBy.firstName }}
                              {{ lead.updatedBy.lastName }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="activities">
            <activities-table :read-only="!checkPermissions" />
          </b-tab-item>

          <b-tab-item label="documents">
            <documents-table :read-only="!checkPermissions" />

            <!-- documents form component -->
            <b-sidebar
              v-model="isSidebarActive"
              type="is-white"
              :fullheight="true"
              :overlay="false"
              :right="true"
            >
              <documents-form
                :files-list="filesList"
                @remove-file="removeFile"
                @clear-files="clearFiles"
                @save-files="createFiles"
              />
            </b-sidebar>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import defaultImage from '@/assets/images/avatar.svg';

import DocumentsMixin from '@/mixins/DocumentsMixin';

import { PageHeader } from '@/components/Shared';
import { ActivitiesTable, ActivitiesTabs } from '@/components/Activities';
import { DocumentsTable, DocumentsForm } from '@/components/Documents';
import { LeadsDetails } from '@/components/Leads';
import { SearchBar } from '@/components/Search';

export default {
  name: 'ViewLead',

  components: {
    PageHeader,
    DocumentsTable,
    DocumentsForm,
    LeadsDetails,
    ActivitiesTable,
    ActivitiesTabs,
    SearchBar,
  },

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  mixins: [DocumentsMixin],

  data: () => ({
    defaultImage,
    isLoading: true,
    isSidebarActive: false,
    activeTab: 0,
  }),

  computed: {
    ...mapGetters({
      lead: 'Leads/getLeadView',
      influencerList: 'Leads/getLeadInfluencersView',
      temperatures: 'Lookups/getTemperatures',
      customFieldsList: 'Settings/getLeadOppCustomFields',
      readOnly: 'Leads/getReadPermissions',
    }),

    checkPermissions() {
      return this.readOnly;
    },

  },

  async created() {
    try {
      await this.$store.dispatch('Lookups/fetchTemperatures');
      await this.$store.dispatch('Leads/fetchLeadPermissions', this.$route.params.leadId);
      await this.$store.dispatch('Leads/fetchLead', this.$route.params.leadId);
      await this.$store.dispatch('Settings/fetchCustomFields', 'Lead_Opportunity');
      const influencersRes = await this.$store.dispatch('DataGrids/fetchInfluencers', {
        opportunityId: this.$route.params.leadId,
        page: 0,
        size: 100,
        sort: ['updatedAt', 'desc'],
        filters: '',
      });
      this.$store.commit('Leads/SET_LEAD_INFLUENCERS_VIEW', influencersRes);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    if (this.$route.query.activeTab) {
      this.setTab(parseInt(this.$route.query.activeTab, 10));
    }
  },

  destroyed() {
    this.$store.commit('Leads/CLEAR_LEAD_VIEW');
  },

  methods: {
    openSidebar() {
      this.activeTab = 2;
      this.isSidebarActive = true;
    },

    setTab(value) {
      this.activeTab = value;
    },

    async convertToOpportunity() {
      try {
        const response = await this.$store.dispatch('Leads/convertToOpportunity', this.$route.params.leadId);
        await this.$router.push(`/opportunities/${response.id}/edit`);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
  .mx-leftCol .level {
    align-items: flex-start;
  }

  .mx-leftCol .level:not(:last-child) {
    margin-bottom: 0;
  }
</style>
