import { mapGetters } from 'vuex';
import {
  DocumentsForm,
  DocumentsTable,
  ShareLinkModal,
} from '@/components/Documents';

export default {
  components: {
    DocumentsForm,
    DocumentsTable,
    ShareLinkModal,
  },

  data: () => ({
    isSidebarActive: false,
    isShareLinkModalActive: false,
  }),

  computed: mapGetters({
    isLoading: 'Documents/getLoading',
    documents: 'Documents/getDocuments',
    filesList: 'Documents/getFilesList',
    file: 'Documents/getFile',
  }),

  methods: {
    /**
     * shareDocument
     *
     * Open ShareLinkModal | Set FILE_DOCUMENT_ID to documentId
     *
     * @param {Int} documentId
     * @param {String} fileName
     */
    shareDocument(documentId, fileName) {
      this.$store.commit('Documents/SET_FILE_DOCUMENT_ID', documentId);
      this.$store.commit('Documents/SET_FILE_FILENAME', fileName);
      this.isShareLinkModalActive = true;
    },

    /**
     * shareLink
     *
     * Share a document as a link to a user
     *
     * @param {Int} userId
     */
    async shareLink(userId) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Documents/shareDocument', {
          documentId: this.file.documentId,
          user: { userId },
        });
        this.isShareLinkModalActive = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * removeFile
     *
     * Remove a single file from a bulk upload by it's index
     *
     * @param {*} index
     */
    removeFile(index) {
      this.$store.commit('Documents/REMOVE_FILE', index);
      this.isSidebarActive = true;
    },

    /**
     * clearFiles
     *
     * Clear all bulk uploaded files
     *
     */
    clearFiles() {
      this.$store.commit('Documents/CLEAR_FILES');
      this.isSidebarActive = false;
    },

    // eslint-disable-next-line consistent-return
    async getFile(file) {
      const reader = new FileReader();
      if (this.$route.name === 'ViewAccount') {
        return new Promise((resolve) => {
          reader.onload = () => resolve({
            fileName: file.name,
            file: reader.result,
            accountId: this.$route.params.accountId,
          });
          reader.readAsDataURL(file);
        });
      }

      if (this.$route.name === 'ViewContact') {
        return new Promise((resolve) => {
          reader.onload = () => resolve({
            fileName: file.name,
            file: reader.result,
            contactId: this.$route.params.contactId,
          });
          reader.readAsDataURL(file);
        });
      }

      if (this.$route.name === 'ViewLead') {
        return new Promise((resolve) => {
          reader.onload = () => resolve({
            fileName: file.name,
            file: reader.result,
            opportunityId: this.$route.params.leadId,
          });
          reader.readAsDataURL(file);
        });
      }

      if (this.$route.name === 'ViewOpportunity') {
        return new Promise((resolve) => {
          reader.onload = () => resolve({
            fileName: file.name,
            file: reader.result,
            opportunityId: this.$route.params.opportunityId,
          });
          reader.readAsDataURL(file);
        });
      }
    },

    createFiles() {
      Promise.all(this.filesList.map((file) => this.getFile(file))).then(async (data) => {
        await this.$store.dispatch('Documents/createDocuments', { files: data });
        this.clearFiles();
        if (this.$route.name === 'ViewAccount') {
          await this.$store.dispatch('DataGrids/fetchDocuments', {
            page: 0,
            perPage: 50,
            sort: ['createdAt', 'desc'],
            filters: `&${new URLSearchParams({
              accountId: this.$route.params.accountId,
            }).toString()}`,
          });
        }

        if (this.$route.name === 'ViewContact') {
          await this.$store.dispatch('DataGrids/fetchDocuments', {
            page: 0,
            perPage: 50,
            sort: ['createdAt', 'desc'],
            filters: `&${new URLSearchParams({
              contactId: this.$route.params.contactId,
            }).toString()}`,
          });
        }

        if (this.$route.name === 'ViewLead') {
          await this.$store.dispatch('DataGrids/fetchDocuments', {
            page: 0,
            perPage: 50,
            sort: ['createdAt', 'desc'],
            filters: `&${new URLSearchParams({
              opportunityId: this.$route.params.leadId,
            }).toString()}`,
          });
        }

        if (this.$route.name === 'ViewOpportunity') {
          await this.$store.dispatch('DataGrids/fetchDocuments', {
            page: 0,
            perPage: 50,
            sort: ['createdAt', 'desc'],
            filters: `&${new URLSearchParams({
              opportunityId: this.$route.params.opportunityId,
            }).toString()}`,
          });
        }
      });
    },
  },
};
