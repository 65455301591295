<template>
  <div>
    <template v-if="accounts.length === 0">
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="account"
      >
        <b-field
          class="is-primary is-required"
          label="Assign to Account"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-autocomplete
            v-model="account"
            icon-right="search"
            expanded
            field="name"
            :data="accountsSearchResults"
            :loading="isLoading"
            @typing="getAccountsAsyncData"
            @select="option => selectedAccount = option"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    customer number: {{ props.option.externalId }}
                  </small>
                </div>
              </div>
            </template>

            <template #empty>
              No results for {{ account }}
            </template>
          </b-autocomplete>
        </b-field>
      </validation-provider>
    </template>

    <template v-else>
      <b-field
        class="is-primary is-required"
        label="Assign to Account"
      >
        <b-autocomplete
          v-model="account"
          icon-right="search"
          expanded
          field="name"
          :data="accountsSearchResults"
          :loading="isLoading"
          @typing="getAccountsAsyncData"
          @select="option => selectedAccount = option"
        >
          <template slot-scope="props">
            <div class="media">
              <div class="media-content">
                {{ props.option.name }}
                <br>
                <small>
                  customer number: {{ props.option.externalId }}
                </small>
              </div>
            </div>
          </template>
        </b-autocomplete>
      </b-field>
    </template>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import debounce from '@/utils/debounce';

export default {
  name: 'ContactsMembershipForm',

  components: {
    ValidationProvider,
  },

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  data: () => ({
    isLoading: false,
    selectedAccount: null,
    accountsSearchResults: [],
  }),

  computed: {
    ...mapGetters({
      accounts: 'Accounts/getAccounts',
    }),

    account: {
      get() {
        return this.$store.state.Contacts.contact.account;
      },

      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_ACCOUNT', value);
      },
    },
  },

  watch: {
    selectedAccount(value) {
      if (this.$route.name === 'AddContact') {
        this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', value.id);
      }
      if (this.$route.name === 'EditContact') { this.addContactAccount(value.id); }
    },
  },

  methods: {
    getAccountsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('DataGrids/fetchAccounts', {
          page: 0,
          size: 50,
          sort: ['createdAt', 'desc'],
          filters: `&${new URLSearchParams({ name: token }).toString()}`,
        });
        this.accountsSearchResults = response;
      } catch (error) {
        this.accountsSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    async addContactAccount(accountId) {
      try {
        await this.$store.dispatch('Contacts/createContactAccount', {
          contactId: this.$route.params.contactId,
          accountId,
        });
        await this.$store.dispatch('Accounts/fetchContactAccounts', {
          contactId: this.$route.params.contactId,
          page: 0,
          perPage: 5,
          sort: ['createdAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

};
</script>

<style>

</style>
