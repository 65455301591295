<template>
  <div>
    <div class="content">
      <h4 class="title is-uppercase has-text-primary has-text-weight-bold">
        account information
      </h4>
    </div>

    <div class="box has-background-light">
      <div class="content">
        <h4 class="subtitle">
          Owners
        </h4>
      </div>

      <div
        v-if="loading"
        class="columns"
      >
        <div
          v-for="(i, index) in 2"
          :key="index"
          class="column"
        >
          <article class="media">
            <figure class="media-left">
              <p class="image is-128x128">
                <b-skeleton
                  circle
                  width="128px"
                  height="128px"
                />
              </p>
            </figure>

            <div class="media-content">
              <div class="content">
                <p>
                  <b-skeleton
                    width="80%"
                    :animated="true"
                  />
                  <b-skeleton
                    width="20%"
                    :animated="true"
                  />
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>

      <template v-else>
        <div
          v-if="account.owners && account.owners.length > 0"
          class="columns"
        >
          <div
            v-for="(owner, index) in account.owners"
            :key="index"
            class="column"
          >
            <article
              v-if="owner.owner"
              class="media"
            >
              <figure class="media-left">
                <p class="image is-64x64">
                  <img
                    style="width: 64px; height: 64px;"
                    class="is-rounded"
                    :src="owner.owner.userPhoto ? owner.owner.userPhoto : defaultImage"
                    alt="user"
                  >
                </p>
              </figure>

              <div class="media-content">
                <div class="content">
                  <strong>
                    {{ `
                      ${owner.owner.firstName}
                      ${owner.owner.middleName}
                      ${owner.owner.lastName}`.trim()
                    }}
                  </strong>
                  <br>
                  <b-tag> {{ owner.percentage }} % </b-tag>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div v-else>
          <b-message> Account: {{ account.name }} has no assigned owner </b-message>
        </div>
      </template>
    </div>

    <div
      v-if="account.description"
      class="content"
    >
      <blockquote>
        {{ account.description }}
      </blockquote>
    </div>

    <div class="columns">
      <div class="column is-half">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Account Name
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <span v-else>
                {{ account.name }}
              </span>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Parent Account
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <router-link
                  v-if="account.parent"
                  class="has-text-weight-bold has-text-info"
                  :to="{ name: 'ViewAccount', params: { accountId: account.parent.id }}"
                >
                  {{ account.parent.name }}
                </router-link>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Account Type
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{
                  account.accountType ? account.accountType.value : ''
                }}
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Hedberg Customer #
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{
                  account.externalId
                }}
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Active
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.isActive ? 'Yes' : 'No' }}
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Account #
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.id }}
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Industry
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <span v-if="account.companyInformation && account.companyInformation.industry">
                  {{ account.companyInformation.industry.value }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Annual Revenue
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <span v-if="account.companyInformation">
                  {{ account.companyInformation.annualRevenue | currency }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Website
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <span class="has-text-info has-text-weight-semibold">
                  {{ account.website }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Date Added
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.createdAt | date }}
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="divider">

    <div class="columns">
      <div class="column">
        <div class="content">
          <h4 class="subtitle has-text-primary is-uppercase">
            Custom Fields
          </h4>
        </div>
        <div
          v-for="(item, key) in customFieldsList"
          :key="key"
          class="level is-mobile"
        >
          <div class="level-left">
            <div class="level-item">
              <p class="has-text-weight-bold is-capitalized">
                {{ item.label }}
              </p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>

              <template v-else>
                <span
                  v-if="account.customFieldEntries.length > 0"
                  class="has-text-grey"
                >
                  <template
                    v-if="account.customFieldEntries
                      .filter((i) => i.customField.id === item.id).length > 0"
                  >
                    <template v-if="item.type === 'CURRENCY'">
                      {{ account.customFieldEntries.filter(
                        (i) => i.customField.id === item.id)[0].value | currency }}
                    </template>
                    <template v-else>
                      {{ account.customFieldEntries
                        .filter((i) => i.customField.id === item.id)[0].value.split(',').length > 1
                        ? account.customFieldEntries
                          .filter((i) => i.customField.id === item.id)[0].value.split(',')
                          .join(', ')
                        : account.customFieldEntries
                          .filter((i) => i.customField.id === item.id)[0].value }}
                    </template>
                  </template>
                  <template
                    v-else
                  >
                    {{ '--' }}
                  </template>
                </span>
                <span
                  v-else
                  class="has-text-grey"
                >
                  {{ '--' }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="divider">

    <div class="content">
      <h4 class="title is-uppercase has-text-primary has-text-weight-bold">
        address information
      </h4>
    </div>

    <div class="columns">
      <div class="column is-half">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Street 1
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <span
                  v-if="account.addressInformation"
                >
                  {{
                    account.addressInformation.street1
                      ? $options.filters.capitalize(account.addressInformation.street1) : ''
                  }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Street 2
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <span v-if="account.addressInformation">
                  {{
                    account.addressInformation.street2
                      ? $options.filters.capitalize(account.addressInformation.street2) : ''

                  }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                City
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <span v-if="account.addressInformation">
                  {{
                    account.addressInformation.city
                      ? $options.filters.capitalize(account.addressInformation.city) : ''
                  }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                State
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <span v-if="account.addressInformation">
                  {{
                    account.addressInformation.state
                      ? $options.filters.capitalize(account.addressInformation.state) : ''
                  }}
                </span>
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Zip
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                <span
                  v-if="account.addressInformation"
                >
                  {{ account.addressInformation.zip ? account.addressInformation.zip : '' }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-half">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Phone
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.phone }}
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Fax
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.fax }}
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                D&amp;B Company
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.dandBCompany }}
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                DUNS Number
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.dunsNumber }}
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                NAICS Code
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.naicsCode }}
              </template>
            </div>
          </div>
        </div>

        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <strong>
                Ticker
              </strong>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <span
                v-if="loading"
                style="width: 120px"
              >
                <b-skeleton :animated="true" />
              </span>
              <template v-else>
                {{ account.ticker | uppercase }}
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/images/avatar.svg';

export default {
  name: 'AccountsOverview',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    account: {
      type: Object,
      required: true,
    },

    customFieldsList: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    defaultImage,
  }),
};
</script>

<style></style>
