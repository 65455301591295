<template>
  <fieldset>
    <b-field>
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="name"
      >
        <b-field
          horizontal
          label="Lead Name"
          label-for="leadName"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            id="leadName"
            v-model="name"
          />
        </b-field>
      </validation-provider>
    </b-field>

    <b-field
      horizontal
      label="Description"
      label-for="description"
    >
      <b-input
        id="description"
        v-model="description"
        type="textarea"
      />
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="stage"
    >
      <b-field
        horizontal
        label="Status"
        label-for="status"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-select
          id="status"
          v-model="stageId"
        >
          <option
            v-for="(stage, index) in leadStages"
            :key="index"
            :value="stage.id"
          >
            {{ stage.value.replace("Qualifed", "Contacted") }}
          </option>
        </b-select>
      </b-field>
    </validation-provider>

    <validation-provider
      v-if="leadStages.length > 0 && hasReasons"
      v-slot="{ errors }"
      ref="closingReasonField"
      :rules="{'required': required}"
      name="closing reason"
    >
      <b-field
        v-if="stageId === 145 || stageId === 146"
        horizontal
        label="Closing reason"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        :class="{'is-required': required}"
      >
        <div class="columns">
          <div class="column is-half">
            <b-select
              v-model="closingReason"
              expanded
              :loading="isloadingReasons"
            >
              <option
                v-for="(reason, index) in opportunityClosingReasons"
                :key="index"
                :value="reason.id"
              >
                {{ reason.reasonValue }}
              </option>
            </b-select>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <br>

    <b-field
      horizontal
      label="Temperature"
    >
      <div class="columns is-vcentered is-mobile">
        <div
          v-for="(temperature, index) in temperatures"
          :key="index"
          class="column is-narrow"
        >
          <b-tooltip
            type="is-info"
            :label="temperature.value"
            position="is-bottom"
          >
            <b-radio-button
              v-model="temperatureId"
              class="is-bordered"
              type="is-info"
              :native-value="temperature.id"
              outlined
            >
              <b-icon
                v-if="temperature.value === 'Cold'"
                icon="snowflake"
                class="has-text-grey"
              />
              <b-icon
                v-if="temperature.value === 'Hot'"
                icon="fire"
                class="has-text-grey"
              />
              <b-icon
                v-if="temperature.value === 'Warm'"
                icon="water"
                class="has-text-grey"
              />
            </b-radio-button>
          </b-tooltip>
        </div>

        <div class="column is-narrow">
          <b-button
            v-if="temperatureId"
            type="is-danger"
            icon-right="close"
            @click="clearTemperatureId"
          />
        </div>
      </div>
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="owner"
    >
      <b-field
        horizontal
        label="Owner"
        label-for="owner"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              id="owner"
              v-model="owner"
              icon-right="search"
              expanded
              field="fullName"
              :data="userSearchResults"
              :loading="isLoading"
              @typing="getUsersAsyncData"
              @select="(option) => (selectedUser = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    <span class="is-capitalized">
                      {{ `${props.option.firstName} ${props.option.lastName}` }}
                    </span>
                    <br>
                    <small>
                      email: {{ props.option.email }} <br>
                      Phone: {{ props.option.phoneCell }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ owner }}
              </template>
            </b-autocomplete>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules=""
      name="co-owner"
    >
      <b-field
        horizontal
        label="CO-Owner"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              v-model="coOwner"
              icon-right="search"
              expanded
              field="fullName"
              :data="coOwnerSearchResults"
              :loading="isLoading"
              @typing="getCoOwnersAsyncData"
              @select="(option) => (selectedCoOwner = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.firstName }}
                    {{ props.option.lastName }}
                    <br>
                    <small>
                      email: {{ props.option.email }} <br>
                      Phone: {{ props.option.phoneCell }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ coOwner }}
              </template>
            </b-autocomplete>
          </div>

          <div class="column is-narrow">
            <div
              v-if="coOwner"
              @click="clearCoOwner"
            >
              <b-button
                type="is-danger"
                icon-right="close"
              />
            </div>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="% Split"
    >
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                rules="integer|max_value:100"
                name="owner percentage"
              >
                <b-field
                  label="Owner"
                  label-position="inside"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                >
                  <b-input
                    v-model="ownerPercentage"
                    min="0"
                    max="100"
                    type="number"
                    :disabled="!coOwnerId"
                  />
                </b-field>
              </validation-provider>
            </div>

            <div class="column">
              <b-field>
                <b-slider
                  v-model="percentage"
                  :min="0"
                  :max="100"
                  type="is-info"
                  :disabled="!coOwnerId"
                />
              </b-field>
            </div>

            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                rules="integer|max_value:100"
                name="co owner percentage"
              >
                <b-field
                  label="CO-Owner"
                  label-position="inside"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                >
                  <b-input
                    v-model="coOwnerPercentage"
                    min="0"
                    max="100"
                    type="number"
                    :disabled="!coOwnerId"
                  />
                </b-field>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="primary account"
    >
      <b-field
        horizontal
        label="Primary Account"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              v-model="account"
              class="autoCompleteLink"
              icon-right="search"
              expanded
              field="name"
              :data="accountsSearchResults"
              :loading="isLoading"
              @typing="getAccountsAsyncData"
              @select="(option) => (selectedAccount = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small> customer number: {{ props.option.externalId }} </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ account }}
                <a
                  href="#"
                  class="link"
                  @click="openModal('newAccountFormModal')"
                >Create New Account instead.</a>
              </template>
            </b-autocomplete>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <b-field
      v-if="account"
      horizontal
      label="Primary Contact"
    >
      <div class="columns is-multiline">
        <div class="column is-half">
          <b-autocomplete
            v-model="primaryContact"
            class="autoCompleteLink"
            :data="contactsSearchResults"
            field="contactName"
            :loading="isLoadingContacts"
            :open-on-focus="true"
            @select="(option) => (selectedContact = option)"
            @typing="getContactsAsyncData"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <img
                    width="32"
                    :src="
                      props.option.photo ? props.option.photo : defaultImage
                    "
                    :alt="props.option.contactName"
                  >
                </div>
                <div class="media-content">
                  {{ props.option.contactName.trim() }}
                  <br>
                  <small>
                    Phone: {{ props.option.phone }}, Email:
                    <b>{{ props.option.email }}</b>
                  </small>
                </div>
              </div>
            </template>

            <template #empty>
              <template v-if="isLoadingContacts">
                Loading contacts...
              </template>
              <template v-else>
                No results for {{ primaryContact }}
                <a
                  href="#"
                  class="link"
                  @click="openModal('newContactFormModal')"
                >
                  Create New Contact instead.
                </a>
              </template>
            </template>
          </b-autocomplete>
          <a
            href="#"
            class="link"
            @click="openModal('newContactFormModal')"
          >
            Create New Contact
          </a>
        </div>
        <div
          v-if="primaryContact"
          class="column"
        >
          <b-button
            aria-label="Clear Primary Contact"
            type="is-danger"
            icon-right="close"
            @click="clearPrimaryContact"
          />
        </div>
      </div>
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      rules=""
      name="competitor info"
    >
      <b-field
        horizontal
        label="Competitor Info"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <div class="columns">
          <div class="column is-half">
            <b-input
              v-model="competitorInfo"
              type="textarea"
            />
          </div>
        </div>
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="Lead Type"
      label-for="opportunityId"
    >
      <div class="columns">
        <div class="column is-half">
          <b-taginput
            v-model="leadTypes"
            :data="filteredTags"
            autocomplete
            field="value"
            type="is-info"
            :open-on-focus="true"
            placeholder="Add a type"
            @typing="getFilteredTags"
          />
        </div>
      </div>
    </b-field>

    <b-field>
      <validation-provider
        v-slot="{ errors }"
        rules=""
        name="lead source"
      >
        <b-field
          horizontal
          label="Lead Source"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-select v-model="leadsSourceId">
            <option
              v-for="(leadSource, index) in leadSources"
              :key="index"
              :value="leadSource.id"
            >
              {{ leadSource.value }}
            </option>
          </b-select>
        </b-field>
      </validation-provider>
    </b-field>

    <b-field>
      <validation-provider
        v-slot="{ errors }"
        rules=""
        name="tags"
      >
        <b-field
          horizontal
          label="Add Tags"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-taginput
            v-model="tags"
            ellipsis
            icon="tag"
          />
        </b-field>
      </validation-provider>
    </b-field>

    <b-field horizontal>
      <b-button
        type="is-info"
        @click="isInfluencersTableActive = !isInfluencersTableActive"
      >
        manage influencers
      </b-button>
    </b-field>

    <b-field horizontal>
      <leads-influencers-table v-if="isInfluencersTableActive" />
    </b-field>

    <br>

    <div
      v-if="customFieldsList.length > 0"
      class="customFieldsList"
    >
      <div class="content">
        <h3 class="subtitle has-text-primary is-capitalized">
          Custom Fields
        </h3>
      </div>
      <hr class="divider">

      <validation-provider
        v-for="(field, index) in customFieldsList"
        :key="index"
        v-slot="{ errors }"
        :rules="{required: field.isRequired}"
        :name="field.label"
      >
        <b-field
          v-if="field.type === 'TEXT_INPUT'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column is-half">
              <b-input v-model="field.value" />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'CURRENCY'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column">
              <currency-input
                v-model="field.value"
                class="input"
              />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'DROP_DOWN'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column is-half">
              <b-select
                v-model="field.value"
                expanded
              >
                <option
                  v-for="(option, optionIndex) in field.lookups"
                  :key="optionIndex"
                  :value="option.value"
                >
                  {{ option.value }}
                </option>
              </b-select>
            </div>
            <div class="column">
              <b-button
                aria-label="Clear Value"
                type="is-danger"
                icon-right="close"
                @click="clearValue(index)"
              />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'MULTI_SELECT'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column is-half">
              <b-taginput
                v-model="field.value"
                :data="field.lookups.map(i => i.value)"
                autocomplete
                field="value"
                type="is-info"
                :open-on-focus="true"
                placeholder="Select one or multiple"
                @typing="(val) => getCustomFieldFilteredTags(val, field)"
              />
            </div>
          </div>
        </b-field>
      </validation-provider>
    </div>

    <br>

    <div class="content">
      <h3 class="subtitle has-text-primary is-capitalized">
        Estimated Potential and Milestones
      </h3>
    </div>

    <hr class="divider">

    <opportunities-estimated-potentials-form :probabilities="probabilities" />

    <br>

    <!-- <div class="content">
      <h3 class="subtitle has-text-primary is-capitalized">
        Lead Details
      </h3>
    </div>

    <hr class="divider"> -->

    <br>

    <div class="content">
      <h4 class="subtitle has-text-primary is-capitalized">
        Project ID
      </h4>
    </div>

    <hr class="divider">

    <b-field
      horizontal
      label="Project Title"
    >
      <div class="columns">
        <div class="column is-narrow">
          <!-- project folders form field -->
          <b-autocomplete
            v-model="projectFolderTitle"
            expanded
            icon-right="search"
            :data="projectFoldersSearchResults"
            :loading="isLoading"
            @typing="getProjectFoldersAsyncData"
            @select="(option) => (selectedProjectFolder = option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-contet">
                  <strong>Title</strong>: {{ props.option.projectTitle }} <br>
                  <strong>ID</strong>: {{ props.option.projectId }}
                </div>
              </div>
            </template>

            <template #empty>
              No results for {{ projectFolderTitle }}
            </template>
          </b-autocomplete>
        </div>
      </div>
    </b-field>

    <b-field
      v-for="(opportunityProjectFolder, index) in opportunityProjectFolders"
      :key="index"
      horizontal
    >
      <div class="columns">
        <div class="column is-narrow">
          <b-input
            :value="
              `${opportunityProjectFolder.projectTitle} - ${opportunityProjectFolder.projectId}`
            "
            disabled
          />
        </div>

        <div class="column">
          <b-button
            type="is-danger"
            icon-right="close"
            @click="removeProjectFolder(opportunityProjectFolder)"
          />
        </div>
      </div>
    </b-field>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="create data form modal"
      aria-modal
    >
      <template #default="props">
        <AccountsFormModal
          v-if="isFormModalAccount"
          module-name="leads"
          @close="props.close"
        />

        <ContactsFormModal
          v-if="isFormModalContact"
          module-name="leads"
          :selected-account="selectedAccount"
          @close="props.close"
          @set-contact="setPrimaryContact"
        />
      </template>
    </b-modal>
  </fieldset>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import debounce from '@/utils/debounce';

import defaultImage from '@/assets/images/avatar.svg';
import { AccountsFormModal } from '@/components/Accounts';
import { ContactsFormModal } from '@/components/Contacts';
import OpportunitiesEstimatedPotentialsForm from '@/components/Opportunities/OpportunitiesEstimatedPotentialsForm.vue';
import LeadsInfluencersTable from './LeadsInfluencersTable.vue';

export default {
  name: 'LeadsForm',

  components: {
    AccountsFormModal,
    ContactsFormModal,
    ValidationProvider,
    OpportunitiesEstimatedPotentialsForm,
    LeadsInfluencersTable,
  },

  props: {
    leadStages: {
      type: Array,
      required: true,
    },

    temperatures: {
      type: Array,
      required: true,
    },

    industries: {
      type: Array,
      required: true,
    },

    leadSources: {
      type: Array,
      required: true,
    },

    noOfEmployees: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    isLoadingContacts: false,
    isloadingReasons: false,
    isInfluencersTableActive: false,
    selectedAccount: null,
    accountsSearchResults: [],
    defaultImage,
    selectedContact: null,
    selectedUser: null,
    selectedCoOwner: null,
    contactsSearchResults: [],
    userSearchResults: [],
    coOwnerSearchResults: [],
    projectFolderTitle: '',
    projectFoldersSearchResults: [],
    selectedProjectFolder: null,

    modalName: null,
    isComponentModalActive: false,

  }),

  computed: {
    ...mapGetters({
      opportunityProjectFolders: 'Leads/getOpportunityProjectFolders',
      opportunityTypes: 'Lookups/getOpportunityTypes',
      opportunityStages: 'Lookups/getOpportunityStages',
      opportunityReasonStages: 'Opportunities/getOpportunityReasonStages',
      opportunityClosingReasons: 'Opportunities/getOpportunityStageReasons',
      probabilities: 'Lookups/getProbabilities',
    }),

    filteredTags() {
      return [...this.opportunityTypes];
    },

    required() {
      if ((this.stageId === 143 || this.stageId === 144
      || this.stageId === 145 || this.stageId === 146)
      && this.leadStages.length > 0) {
        const { code } = this.leadStages.filter((i) => i.id === this.stageId)[0];
        let stage;
        if (code === 'closed_converted') {
          stage = 'closed_won';
        } else if (code === 'closed_not_converted') {
          stage = 'closed_lost';
        } else {
          stage = code;
        }
        const result = this.leadStages.filter((i) => i.stageCode === stage);
        return result.length > 0 ? result[0].isRequired : false;
      }
      return false;
    },

    hasReasons() {
      if ((this.stageId === 145 || this.stageId === 146)
      && this.leadStages.length > 0) {
        const { code } = this.leadStages.filter((i) => i.id === this.stageId)[0];
        let stage;
        if (code === 'closed_converted') {
          stage = 'closed_won';
        } else if (code === 'closed_not_converted') {
          stage = 'closed_lost';
        } else {
          stage = code;
        }
        const result = this.opportunityReasonStages.filter((i) => i.stageCode === stage);
        return result.length > 0;
      }
      return false;
    },

    name: {
      get() {
        return this.$store.state.Leads.lead.name;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_NAME', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Leads.lead.description;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_DESCRIPTION', value);
      },
    },

    stageId: {
      get() {
        return this.$store.state.Leads.lead.stageId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_STAGE_ID', value);
      },
    },

    closingReason: {
      get() {
        return this.$store.state.Leads.lead.reasonId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_CLOSING_REASON', value);
      },
    },

    temperatureId: {
      get() {
        return this.$store.state.Leads.lead.temperatureId;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_TEMPERATURE_ID', value);
      },
    },

    owner: {
      get() {
        return this.$store.state.Leads.lead.owner.owner;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_OWNER', value);
      },
    },

    account: {
      get() {
        return this.$store.state.Leads.lead.account;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_ACCOUNT', value);
      },
    },

    accountId: {
      get() {
        return this.$store.state.Leads.lead.accountId;
      },
    },

    primaryContact: {
      get() {
        return this.$store.state.Leads.lead.primaryContact;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT', value);
      },
    },

    competitorInfo: {
      get() {
        return this.$store.state.Leads.lead.competitorInfo;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_COMPETITOR_INFO', value);
      },
    },

    leadsSourceId: {
      get() {
        return this.$store.state.Leads.lead.leadsSourceId;
      },
      set(value) {
        return this.$store.commit('Leads/SET_LEAD_LEADS_SOURCE_ID', value);
      },
    },

    estimatedRevenue: {
      get() {
        return this.$store.state.Leads.lead.estimatedRevenue;
      },
      set(value) {
        return this.$store.commit('Leads/SET_LEAD_ESTIMATED_REVENUE', value);
      },
    },

    tags: {
      get() {
        return this.$store.state.Leads.lead.tags.map((i) => i.tag || i);
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_TAGS', value);
      },
    },

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.leadOppCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LEAD_OPP_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Leads.lead.customFields;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_CUSTOM_FIELDS', value);
      },
    },

    lead: {
      get() {
        return this.$store.state.Leads.lead;
      },

      // set(value) {
      //   return this.$store.commit('Leads/SET_LEAD_CUSTOM_FIELDS', value);
      // },
    },

    leadTypes: {
      get() {
        const newArr = this.$store.state.Leads.lead.leadTypes;
        return this.opportunityTypes.filter((i) => {
          const arr = newArr.filter((o) => o === i.id);
          if (arr.length > 0) {
            return i.id === arr[0];
          }
          return false;
        });
      },

      set(value) {
        const newArr = value.map((i) => i.id);
        return this.$store.commit('Leads/SET_LEAD_TYPE_ID', newArr);
      },
    },

    oppStageId: {
      get() {
        return parseInt(this.$store.state.Leads.lead.oppStageId, 10);
      },

      set(value) {
        return this.$store.commit('Leads/SET_OPP_STAGE_ID', parseInt(value, 10));
      },
    },

    percentage: {
      get() {
        return this.$store.state.Leads.lead.owner.percentage;
      },

      set(value) {
        this.$store.commit('Leads/SET_LEAD_CO_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Leads/SET_LEAD_OWNER_PERCENTAGE', value);
      },
    },

    coOwner: {
      get() {
        return this.$store.state.Leads.lead.coOwner.owner;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_CO_OWNER', value);
      },
    },

    coOwnerId: {
      get() {
        return this.$store.state.Leads.lead.coOwner.id;
      },
    },

    ownerPercentage: {
      get() {
        return this.$store.state.Leads.lead.owner.percentage;
      },

      set(value) {
        this.$store.commit('Leads/SET_LEAD_CO_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Leads/SET_LEAD_OWNER_PERCENTAGE', value);
      },
    },

    coOwnerPercentage: {
      get() {
        return this.$store.state.Leads.lead.coOwner.percentage;
      },

      set(value) {
        this.$store.commit('Leads/SET_LEAD_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Leads/SET_LEAD_CO_OWNER_PERCENTAGE', value);
      },
    },

    isFormModalAccount() {
      return this.modalName === 'newAccountFormModal';
    },

    isFormModalContact() {
      return this.modalName === 'newContactFormModal';
    },

    maxStage() {
      const data = [...this.opportunityStages];
      const orderSequence = data.map((el) => el.orderSequence).sort();
      return Math.max(...orderSequence);
    },

    minStage() {
      const data = [...this.opportunityStages];
      const orderSequence = data.map((el) => el.orderSequence).sort();
      return Math.min(...orderSequence);
    },
  },

  watch: {
    customFieldsList: {
      handler(value) {
        if (value && value.length > 0) {
          if (this.$route.name === 'AddLead') {
            this.$store.commit('Leads/SET_LEAD_CUSTOM_FIELDS', value.map((item) => (
              {
                customFieldId: item.id,
                value: Array.isArray(item.value)
                  ? item.value.join(',') : item.value || null,
              })));
          } else if (this.$route.name === 'EditLead' && this.lead
          && this.lead.customFields && this.customFields[0].id !== (undefined || null)) {
            this.$store.commit('Leads/SET_LEAD_CUSTOM_FIELDS', value.map((item, index) => (
              {
                customFieldId: this.customFields[index].customFieldId,
                customFieldEntryId: this.customFields[index].customFieldEntryId,
                value: Array.isArray(item.value)
                  ? item.value.join(',') : item.value || null,
              })));
          }
        }
      },
      deep: true,
    },

    lead: {
      async handler() {
        await this.mapFields();

        if (this.customFieldsList.length > 0) {
          const customFields = [...this.customFieldsList].map((item, index) => {
            if (item.type === 'MULTI_SELECT') {
              return ({
                ...item,
                value: this.customFields[index]?.value ? this.customFields[index]?.value.split(',') : null,
              });
            }
            return ({
              ...item,
              value: this.customFields[index]?.value && this.customFields[index]?.value.split(',').length > 1
                ? this.customFields[index]?.value.split(',') : this.customFields[index].value || null,
            });
          });
          this.$store.commit('Settings/SET_LEAD_OPP_CUSTOM_FIELDS', customFields);
        }

        if (this.customFieldsList.length > 0 && this.$route.name === 'AddLead') {
          const fieldValues = this.customFieldsList.map((item) => ({
            customFieldEntryId: item.id,
            value: item.value || null,
          }));
          this.$store.commit('Leads/SET_LEAD_CUSTOM_FIELDS', fieldValues);
        }
      },
    },

    stageId: {
      async handler(value) {
        if (value === 145 || value === 146) {
          if (this.opportunityReasonStages.length > 0) {
            this.isloadingReasons = true;
            this.$store.commit('Leads/SET_LEAD_CLOSING_REASON', null);
            // this.$refs.closingReasonField.reset();
            const { code } = this.leadStages
              .filter((i) => i.id === value)[0];
            let newStage;
            if (code === 'closed_converted') {
              newStage = 'closed_won';
            } else if (code === 'closed_not_converted') {
              newStage = 'closed_lost';
            } else {
              newStage = code;
            }
            const stages = this.opportunityReasonStages.filter((i) => i.stageCode === newStage);
            if (stages.length > 0) {
              const { id } = stages[0];
              this.isLoading = true;
              await this.$store.dispatch('Opportunities/fetchOpportunityStageReasons', id);
              this.isLoading = false;
            }
            this.isloadingReasons = false;
          }
        }
        // return false;
      },
    },

    account() {
      this.contactsSearchResults = [];
    },

    accountId() {
      this.setAccount();
    },

    selectedAccount(value) {
      if (value.id) {
        this.$store.commit('Leads/SET_LEAD_ACCOUNT_ID', value.id);
      }
    },

    selectedContact(value) {
      if (value.id) {
        this.$store.commit(
          'Leads/SET_LEAD_PRIMARY_CONTACT_ID',
          value.id,
        );
      }
    },

    selectedUser(value) {
      this.$store.commit('Leads/SET_LEAD_OWNER_ID', value.userId);
    },

    selectedCoOwner(value) {
      this.$store.commit('Leads/SET_LEAD_CO_OWNER_ID', value.userId);
    },

    selectedProjectFolder(value) {
      if (this.$route.name === 'EditLead') {
        this.$store.dispatch('ProjectFolders/createProjectFolder', {
          opportunityId: this.$route.params.leadId,
          projectFolderId: value.id,
        });
        this.$store.commit('Leads/ADD_LEAD_PROJECT_FOLDER', value);
      } else {
        this.$store.commit('Leads/ADD_LEAD_PROJECT_FOLDER', value);
      }
    },
  },

  async created() {
    try {
      await this.$store.dispatch('Settings/fetchCustomFields', 'Lead_Opportunity');
      await this.$store.dispatch('Opportunities/fetchOpportunityReasonStages');
      if (this.$route.name === 'EditLead') {
        await this.$store.dispatch('Leads/fetchLead', this.$route.params.leadId);
        const influencersRes = await this.$store.dispatch('DataGrids/fetchInfluencers', {
          opportunityId: this.$route.params.leadId,
          page: 0,
          size: 100,
          sort: ['updatedAt', 'desc'],
          filters: '',
        });
        this.$store.commit('Leads/SET_LEAD_INFLUENCERS', influencersRes);
      }
      this.$store.dispatch('Lookups/fetchAccountTypes');
    } catch (error) {
      console.error(JSON.stringify(error.message));
    } finally {
      this.isLoading = false;
    }
  },

  async mounted() {
    this.setAccount();

    try {
      const response = await this.$store.dispatch('Contacts/fetchContacts', {
        page: 0,
        perPage: 1,
        sort: ['createdAt', 'desc'],
        globalSearch: '',
        filters: `&${new URLSearchParams({
          contactname: 'unassigned',
        }).toString()}`,
      });

      if (
        response.content
      && response.content.length > 0
      && this.$route.name === 'AddLead'
      ) {
        this.$store.commit(
          'Leads/SET_LEAD_PRIMARY_CONTACT',
          `${response.content[0].firstName} ${response.content[0].lastName}`,
        );
        this.$store.commit(
          'Leads/SET_LEAD_PRIMARY_CONTACT_ID',
          response.content[0].id,
        );
      }
    } catch (error) {
      console.error(error);
    }
  },

  destroyed() {
    this.$store.commit('Leads/CLEAR_LEAD');
  },

  methods: {
    async setAccount() {
      this.contactsSearchResults = [];
      this.isLoadingContacts = true;
      const response = await this.$store.dispatch('Contacts/fetchAccountContactList', {
        page: 0,
        size: 1000000,
        sort: ['updatedAt', 'desc'],
        filters: `&${new URLSearchParams({ accountIds: this.accountId, isActive: 'true' }).toString()}`,
      });
      this.contactsSearchResults = response;
      this.isLoadingContacts = false;
    },

    setPrimaryContact(payload) {
      this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT_ID', payload.id);
    },

    mapFields() {
      if (this.customFieldsList && this.customFieldsList.length > 0 && this.$route.name === 'EditLead') {
        const fieldValues = [...this.customFieldsList].map((item) => {
          const filteredArr = [...this.customFields]
            .filter((i) => i.customFieldId === item.id);
          return ({
            customFieldId: item.id,
            customFieldEntryId: filteredArr.length > 0
              ? [...filteredArr][0].customFieldEntryId : null,
            value: filteredArr.length > 0 ? [...filteredArr][0].value : null,
          });
        });
        this.$store.commit('Leads/SET_LEAD_CUSTOM_FIELDS', fieldValues);
      }
    },

    getCustomFieldFilteredTags(text, field) {
      this.filteredTags = field.lookups.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    getFilteredTags(text) {
      this.filteredTags = this.opportunityTypes.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    clearValue(index) {
      const clearedFieldVal = [...this.customFieldsList];
      clearedFieldVal[index].value = '';
      this.$store.commit('Settings/SET_LEAD_OPP_CUSTOM_FIELDS', clearedFieldVal);
    },

    getProjectFoldersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('ProjectFolders/fetchProjectFolders', token);
        this.projectFoldersSearchResults = response.data;
      } catch (error) {
        this.projectFoldersSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    async removeProjectFolder(projectFolder) {
      try {
        if (this.$route.name === 'EditLead') {
          await this.$store.dispatch('ProjectFolders/removeProjectFolder', {
            opportunityId: this.$route.params.leadId,
            projectFolderId: projectFolder.id,
          });
          this.$store.commit(
            'Leads/REMOVE_LEAD_PROJECT_FOLDER', projectFolder.projectId,
          );
        } else {
          this.$store.commit(
            'Leads/REMOVE_LEAD_PROJECT_FOLDER', projectFolder.projectId,
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    async clearPrimaryContact() {
      const response = await this.$store.dispatch('Contacts/fetchAccountContactList', {
        page: 0,
        perPage: 1,
        sort: ['updatedAt', 'desc'],
        filters: `&${new URLSearchParams({ contactName: 'unassigned' }).toString()}`,
      });

      if (response && response.length > 0) {
        this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT', response[0].contactName.trim());
        this.$store.commit(
          'Leads/SET_LEAD_PRIMARY_CONTACT_ID',
          response[0].contactId,
        );
      } else {
        this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT', '');
        this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT_ID', '');
      }
    },

    getAccountsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('DataGrids/fetchAccounts', {
          page: 0,
          size: 50,
          sort: ['createdAt', 'desc'],
          filters: `&${new URLSearchParams({ name: token }).toString()}`,
        });
        this.accountsSearchResults = response;
      } catch (error) {
        this.accountsSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getContactsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Contacts/fetchAccountContactList', {
          page: 0,
          size: 1000000,
          sort: ['updatedAt', 'desc'],
          filters: `&${new URLSearchParams({
            accountIds: this.$store.state.Leads.lead.accountId,
            contactName: token,
          }).toString()}`,
        });
        this.contactsSearchResults = response;
      } catch (error) {
        this.contactsSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getUsersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch(
          'Search/searchUsers',
          token,
        );
        this.userSearchResults = response;
      } catch (error) {
        this.userSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getCoOwnersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.coOwnerSearchResults = response;
      } catch (error) {
        this.coOwnerSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    clearCoOwner() {
      if (this.$route.name === 'EditLead') {
        this.$store.dispatch(
          'Opportunities/deleteOpportunityOwner',
          {
            opportunityId: this.$route.params.leadId,
            id: this.$store.state.Leads.lead.coOwner.id,
          },
        );
        this.$store.commit('Leads/SET_LEAD_CO_OWNER', '');
        this.$store.commit('Leads/SET_LEAD_CO_OWNER_ID', '');
        this.$store.commit('Leads/SET_LEAD_CO_OWNER_PERCENTAGE', 0);
        return this.$store.commit('Leads/SET_LEAD_OWNER_PERCENTAGE', 100);
      }
      this.$store.commit('Leads/SET_LEAD_CO_OWNER', '');
      this.$store.commit('Leads/SET_LEAD_CO_OWNER_ID', '');
      this.$store.commit('Leads/SET_LEAD_CO_OWNER_PERCENTAGE', 0);
      return this.$store.commit('Leads/SET_LEAD_OWNER_PERCENTAGE', 100);
    },

    clearTemperatureId() {
      this.$store.commit('Leads/SET_LEAD_TEMPERATURE_ID', '');
    },

    openModal(modalLabel) {
      this.$store.commit('Accounts/CLEAR_ACCOUNT');
      this.$store.commit('Contacts/CLEAR_CONTACT');
      this.modalName = modalLabel;
      this.isComponentModalActive = true;
    },
  },
};
</script>

<style lang="css" scoped>
.is-bordered {
  border-width: 1px !important;
}

.link {
  color: #1475be;
  font-weight: 600;
}
</style>
