// 3rd party imports
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

// local imports
import states from '@/static/states.json';
import defaultImage from '@/assets/images/avatar.svg';

export default {
  components: {
    ValidationProvider,
  },

  props: {
    contactTypes: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    states,
    defaultImage,
    months: [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' },
    ],
  }),

  computed: {
    ...mapGetters({
      contact: 'Contacts/getContact',
    }),

    owner: {
      get() {
        return this.$store.state.Contacts.contact.owner;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_OWNER', value);
      },
    },

    contactPhoto: {
      get() {
        return this.$store.state.Contacts.contact.contactPhoto;
      },
      set(value) {
        return this.getBase64(value, (imageUrl) => {
          this.$store.commit('Contacts/SET_CONTACT_CONTACT_PHOTO', imageUrl);
        });
      },
    },

    title: {
      get() {
        return this.$store.state.Contacts.contact.title;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_TITLE', value);
      },
    },

    prefix: {
      get() {
        return this.$store.state.Contacts.contact.prefix;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_PREFIX', value);
      },
    },

    firstName: {
      get() {
        return this.$store.state.Contacts.contact.firstName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_FIRST_NAME', value);
      },
    },

    middleName: {
      get() {
        return this.$store.state.Contacts.contact.middleName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_MIDDLE_NAME', value);
      },
    },

    lastName: {
      get() {
        return this.$store.state.Contacts.contact.lastName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_LAST_NAME', value);
      },
    },

    emailAddress: {
      get() {
        return this.$store.state.Contacts.contact.emailAddress;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_EMAIL_ADDRESS', value);
      },
    },

    phone: {
      get() {
        return this.$store.state.Contacts.contact.phone;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_PHONE', value);
      },
    },

    phoneWork: {
      get() {
        return this.$store.state.Contacts.contact.phoneWork;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_WORK_PHONE', value);
      },
    },

    street1: {
      get() {
        return this.$store.state.Contacts.contact.address.street1;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_ADDRESS_STREET_1', value);
      },
    },

    street2: {
      get() {
        return this.$store.state.Contacts.contact.address.street2;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_ADDRESS_STREET_2', value);
      },
    },

    street3: {
      get() {
        return this.$store.state.Contacts.contact.address.street3;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_ADDRESS_STREET_3', value);
      },
    },

    city: {
      get() {
        return this.$store.state.Contacts.contact.address.city;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_ADDRESS_CITY', value);
      },
    },

    state: {
      get() {
        return this.$store.state.Contacts.contact.address.state;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_ADDRESS_STATE', value);
      },
    },

    zip: {
      get() {
        return this.$store.state.Contacts.contact.address.zip;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_ADDRESS_ZIP', value);
      },
    },

    month: {
      get() {
        return this.$store.state.Contacts.month;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_DOB_MONTH', value);
      },
    },

    date: {
      get() {
        return this.$store.state.Contacts.date;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_DOB_DATE', value);
      },
    },

    externalId: {
      get() {
        return this.$store.state.Contacts.contact.externalId;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_EXTERNAL_ID', value);
      },
    },

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.contactCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_CONTACT_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Contacts.contact.customFields;
      },

      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_CUSTOM_FIELDS', value);
      },
    },

    filteredStatesData() {
      return this.states.filter(
        (option) => option.name
          .toString()
          .toLowerCase()
          .indexOf(this.state.toLowerCase()) >= 0,
      );
    },
  },

  watch: {
    selectedUser(value) {
      return this.$store.commit('Contacts/SET_CONTACT_OWNER_ID', value.userId);
    },

    selectedContact(value) {
      return this.$router.push(`/contacts/${value.id}/edit`);
    },

    customFieldsList: {
      handler(value) {
        if (value && value.length > 0) {
          if (this.$route.name === 'AddContact') {
            this.$store.commit('Contacts/SET_CONTACT_CUSTOM_FIELDS', value.map((item) => (
              {
                customFieldId: item.id,
                value: Array.isArray(item.value)
                  ? item.value.join(',') : item.value || null,
              })));
          } else if (this.$route.name === 'EditContact' && this.contact
          && this.contact.customFields.length > 0
          && this.customFields[0].id !== (undefined || null)) {
            this.$store.commit('Contacts/SET_CONTACT_CUSTOM_FIELDS', value.map((item, index) => (
              {
                customFieldId: this.customFields[index].customFieldId,
                customFieldEntryId: this.customFields[index].customFieldEntryId,
                value: Array.isArray(item.value)
                  ? item.value.join(',') : item.value || null,
              })));
          }
        }
      },
      deep: true,
    },
  },

  destroyed() {
    this.$store.commit('Contacts/CLEAR_CONTACT');
  },

  methods: {
    mapValues() {
      if (this.customFieldsList && this.customFieldsList.length > 0 && this.$route.name === 'EditContact') {
        const fieldValues = [...this.customFieldsList].map((item) => {
          const filteredArr = [...this.customFields]
            .filter((i) => i.customFieldId === item.id);
          return ({
            customFieldId: item.id,
            customFieldEntryId: filteredArr.length > 0
              ? [...filteredArr][0].customFieldEntryId : null,
            value: filteredArr.length > 0 ? [...filteredArr][0].value : null,
          });
        });
        this.$store.commit('Contacts/SET_CONTACT_CUSTOM_FIELDS', fieldValues);
      }
    },

    mapFields() {
      if (this.customFieldsList.length > 0
        // && this.customFields.length > 0
        && this.$route.name === 'EditContact') {
        const customFields = [...this.customFieldsList].map((item, index) => {
          if (item.type === 'MULTI_SELECT') {
            return ({
              ...item,
              value: this.customFields[index]?.value ? this.customFields[index]?.value.split(',') : null,
            });
          }
          return ({
            ...item,
            value: this.customFields[index]?.value && this.customFields[index]?.value.split(',').length > 1
              ? this.customFields[index]?.value.split(',') : this.customFields[index]?.value || null,
          });
        });
        this.$store.commit('Settings/SET_CONTACT_CUSTOM_FIELDS', customFields);
      }
    },

    getCustomFieldFilteredTags(text, field) {
      this.filteredTags = field.lookups.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    clearValue(index) {
      const clearedFieldVal = [...this.customFieldsList];
      clearedFieldVal[index].value = '';
      this.$store.commit('Settings/SET_CONTACT_CUSTOM_FIELDS', clearedFieldVal);
    },

    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },

    removeContactPhoto() {
      this.$store.commit('Contacts/SET_CONTACT_CONTACT_PHOTO', null);
    },
  },

};
