<template>
  <div>
    <table-header
      :fields="exportFields"
      :name="'accounts'"
      @export-xls="exportAccounts"
      @search="searchAccount"
    >
      <template #update-button>
        <b-button
          v-if="isEditing"
          ref="saveEntryButton"
          size="is-small"
          class="mr-4"
          icon-left="content-save"
          type="is-success"
          @click="updateAccount"
        >
          save
        </b-button>
        <b-button
          v-if="isEditing"
          ref="cancelEntryButton"
          size="is-small"
          icon-left="close"
          type="is-success"
          @click="cancelUpdate"
        >
          cancel
        </b-button>
      </template>

      <template #re-assign-button>
        <div>
          <b-button
            v-if="checkedRows.length > 0"
            class="modal-btn"
            size="is-small"
            @click="openModal"
          >
            Re-assign Owner
          </b-button>
        </div>
      </template>

      <template #open-switch-button>
        <b-field>
          <b-switch v-model="openSwitch">
            <small class="has-text-weight-bold">
              {{ openSwitch ? "Show Active" : "Show All" }}
            </small>
          </b-switch>
        </b-field>
      </template>

      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0 || searchClause"
          size="is-small"
          icon="power-off"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isBordered"
                size="is-small"
              >
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isCheckable"
                size="is-small"
              >
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isNarrowed"
                size="is-small"
              >
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStickyHeaders"
                size="is-small"
              >
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isScrollable"
                size="is-small"
              >
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStriped"
                size="is-small"
              >
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
          class="dg-columns"
        >
          <b-button
            slot="trigger"
            icon-left="eye"
            size="is-small"
            icon-right="chevron-down"
          >
            columns
          </b-button>

          <draggable
            v-model="columns"
            group="columns"
            handle=".handle"
            @start="drag=true"
            @end="drag=false"
          >
            <b-dropdown-item
              v-for="column in columns"
              :key="column.id"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                  size="is-small"
                >
                  {{ column.label.startsWith('custom')
                    ? getCustomFieldLabel(column.label, 'Account') : column.label }}
                </b-checkbox>
                <i class="grip-vertical handle" />
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="accountsTable"
      v-click-outside-dg="onClickOutsideTable"
      :mobile-cards="false"
      :loading="loading"
      :data="Array.isArray(accounts) ? accounts : []"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :debounce-search="800"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @update:selected="onClickOutsideEditing"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label.startsWith('custom')
          ? getCustomFieldLabel(column.label, 'Account') : column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          ref="search"
          slot-scope="props"
        >
          <b-input
            v-show="
              column.searchField === 'createdAt'
                || column.searchField === 'updatedAt'
                || column.searchField === 'hdsCreateDate'
                || column.searchField === 'hdsLastQuoteDate'
                || column.searchField === 'hdsLastOrderDate'
                || column.searchField === 'hdsLastInvoiceDate'
                || column.searchField === 'lastLeadDate'
                || column.searchField === 'lastOpportunityDate'
            "
            v-model="props.filters[column.searchField]"
            type="date"
            icon="calendar-month"
            size="is-small"
          />

          <b-input
            v-show="
              column.searchField === 'name'
                || column.searchField === 'externalId'
                || column.searchField === 'accountType'
                || column.searchField === 'owners'
                || column.searchField === 'noOfEmployees'
                || column.searchField === 'contacts'
                || column.searchField === 'parent'
                || column.searchField === 'childAccounts'
                || column.searchField === 'annualRevenue'
                || column.searchField === 'industry'
                || column.searchField === 'city'
                || column.searchField === 'state'
                || column.searchField === 'zip'
                || column.searchField === 'streetOne'
                || column.searchField === 'streetTwo'
                || column.searchField === 'streetThree'
                || column.searchField === 'customField1'
                || column.searchField === 'customField2'
                || column.searchField === 'customField3'
                || column.searchField === 'customField4'
                || column.searchField === 'customField5'
                || column.searchField === 'customField6'
                || column.searchField === 'customField7'
                || column.searchField === 'customField8'
                || column.searchField === 'customField9'
                || column.searchField === 'customField10'
            "
            v-model="props.filters[`${column.searchField}`]"
            placeholder="Search..."
            icon="search"
            size="is-small"
          />
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div
              v-if="hasRole('ROLE_BUSINESS_ADMIN')
                || checkOwnership(props.row.ownerIds)
                || isTeammate(props.row.teamIds) "
              class="buttons"
            >
              <b-tooltip
                type="is-info"
                label="Leads"
                position="is-right"
              >
                <b-button
                  aria-label="View Leads"
                  tag="router-link"
                  :to="{
                    name: 'ViewAccount',
                    params: { accountId: props.row.id },
                    query: { activeTab: 2 },
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="star"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Opportunities"
                position="is-right"
              >
                <b-button
                  aria-label="View Opportunities"
                  tag="router-link"
                  :to="{
                    name: 'ViewAccount',
                    params: { accountId: props.row.id },
                    query: { activeTab: 3 },
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="bullseye"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Edit Account"
                position="is-right"
              >
                <b-button
                  aria-label="Edit Account"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="pencil"
                  tag="router-link"
                  :to="{ name: 'EditAccount', params: { accountId: props.row.id } }"
                />
              </b-tooltip>
            </div>
            <div v-else>
              &nbsp;
            </div>
          </template>

          <template v-else-if="column.field === 'name'">
            <router-link
              class="has-text-primary has-text-weight-semibold"
              :to="{ name: 'ViewAccount', params: { accountId: props.row.id } }"
            >
              {{ props.row[column.field] }}
            </router-link>
          </template>

          <template v-else-if="column.field === 'parent'">
            <router-link
              class="has-text-primary has-text-weight-semibold"
              :to="{ name: 'ViewAccount', params: { accountId: props.row.parentId } }"
            >
              {{ props.row[column.field] }}
            </router-link>
          </template>

          <template v-else-if="column.field === 'externalId'">
            <span
              class="is-pointer"
              @dblclick="onEdit(props.row, props)"
            >
              {{ props.row[column.field] }}
            </span>
          </template>

          <b-tooltip
            v-else
            label="Double-click to edit"
            class="is-info"
          >
            <template v-if="column.field === 'childAccounts'">
              <template v-if="props.row.childAccounts">
                <div
                  v-for="(childAccount, childAccountIndex) in props.row.childAccounts.split(',')"
                  :key="childAccountIndex"
                >
                  <b-tag
                    v-if="childAccountIndex <= 2"
                  >
                    {{ childAccount }}
                  </b-tag>
                </div>
                <b-tag v-if="props.row.childAccounts.split(',').length > 3">
                  + {{ props.row.childAccounts.split(',').length - 3 }}
                </b-tag>
              </template>
            </template>

            <template v-else-if="column.field === 'contacts'">
              <template v-if="props.row.contacts">
                <div
                  v-for="(contact, contactIndex) in props.row.contacts.split(',')"
                  :key="contactIndex"
                >
                  <b-tag
                    v-if="contactIndex <= 2"
                  >
                    {{ contact }}
                  </b-tag>
                </div>
                <b-tag v-if="props.row.contacts.split(',').length > 3">
                  + {{ props.row.contacts.split(',').length - 3 }}
                </b-tag>
              </template>
            </template>

            <template v-else-if=" column.field === 'owners' ">
              <template v-if="props.row.owners">
                <div
                  v-for="(owner, ownerIndex) in props.row.owners.split(',')"
                  :key="ownerIndex"
                >
                  <b-taglist
                    attached
                  >
                    <b-tag>
                      {{ owner.split('/')[0] }}
                    </b-tag>
                    <b-tag type="is-info">
                      {{ owner.split('/')[1] }} %
                    </b-tag>
                  </b-taglist>
                </div>
              </template>
            </template>

            <template v-else-if="column.field === 'isActive'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-switch
                  v-model="isActive"
                  size="is-small"
                  type="is-info"
                />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.isActive }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'city'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-input v-model="city" />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.city }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'state'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-autocomplete
                  id="state"
                  v-model="state"
                  field="name"
                  :data="filteredStatesData"
                  :open-on-focus="true"
                  @select="setSelectedState"
                />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.state }}
                </span>
              </template>
            </template>
            <template v-else-if="column.field === 'streetOne'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-input v-model="streetOne" />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.streetOne }}
                </span>
              </template>
            </template>
            <template v-else-if="column.field === 'streetTwo'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-input v-model="streetTwo" />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.streetTwo }}
                </span>
              </template>
            </template>
            <template v-else-if="column.field === 'streetThree'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-input v-model="streetThree" />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.streetThree }}
                </span>
              </template>
            </template>
            <template v-else-if="column.field === 'zipCode'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-input v-model="zip" />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.zipCode }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'annualRevenue'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <currency-input
                  id="annualRevenue"
                  v-model="annualRevenue"
                  class="input"
                />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.annualRevenue | currency }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'industry'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-select
                  id="industry"
                  v-model="industryId"
                  expanded
                >
                  <option
                    v-for="(industry, industryIndex) in industries"
                    :key="industryIndex"
                    :value="industry.id"
                  >
                    {{ industry.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row.industry"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.industry }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'accountType'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-select
                  id="accountType"
                  v-model="typeId"
                  expanded
                >
                  <option
                    v-for="(accountType, accountTypeIndex) in accountTypes"
                    :key="accountTypeIndex"
                    :value="accountType.id"
                  >
                    {{ accountType.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row.accountType"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.accountType }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'noOfEmployees'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedAccountId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))
                "
              >
                <b-select
                  id="noOfEmployees"
                  v-model="noOfEmployeesId"
                  expanded
                >
                  <option
                    v-for="(option, optionIndex) in noOfEmployees"
                    :key="optionIndex"
                    :value="option.id"
                  >
                    {{ option.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row.noOfEmployees"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.noOfEmployees }}
                </span>
              </template>
            </template>

            <template
              v-else-if="column.field === 'createdAt'
                || column.field === 'updatedAt'
                || column.field === 'hdsCreateDate'
                || column.field === 'hdsLastQuoteDate'
                || column.field === 'hdsLastOrderDate'
                || column.field === 'hdsLastInvoiceDate'
                || column.field === 'lastLeadDate'
                || column.field === 'lastOpportunityDate'"
            >
              <template v-if="props.row[column.field]">
                {{ props.row[column.field] | date }}
              </template>
            </template>

            <template v-else-if="column.field.startsWith('custom')">
              <div
                v-if="isEditing && props.row.id === selectedAccountId
                  && customFieldsList.length > 0 && (hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership(props.row.ownerIds) || isTeammate(props.row.teamIds))"
              >
                <validation-provider
                  v-for="(field, fieldIndex) in customFieldsList.filter(
                    (item) => item.label === getCustomFieldLabel(column.label, 'Account'))"
                  :key="fieldIndex"
                  v-slot="{ errors }"
                  :rules="{required: field.isRequired}"
                  :name="field.label"
                >
                  <b-field
                    v-if="field.type === 'TEXT_INPUT'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <b-input
                      v-model="props.row[column.field]"
                      size="is-small"
                      @input="setNewEditedValues(
                        $event, getCustomFieldLabel(column.label, 'Account')
                      )"
                    />
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'CURRENCY'"
                    horizontal
                    :label="field.label"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <div class="columns">
                      <div class="column">
                        <currency-input
                          v-model="props.row[column.field]"
                          size="is-small input"
                          expanded
                          @input="setNewEditedValues(
                            $event, getCustomFieldLabel(column.label, 'Account')
                          )"
                        />
                      </div>
                    </div>
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'DROP_DOWN'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <b-select
                      v-model="props.row[column.field]"
                      size="is-small"
                      expanded
                      @input="setNewEditedValues(
                        $event, getCustomFieldLabel(column.label, 'Account')
                      )"
                    >
                      <option
                        v-for="(option, optionIndex) in field.lookups"
                        :key="optionIndex"
                        :value="option.value"
                      >
                        {{ option.value }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'MULTI_SELECT'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <div class="columns">
                      <div class="column">
                        <b-taginput
                          v-model="props.row[column.field + 'Arr']"
                          v-multi-select-field="{
                            index: props.index,
                            field: column.field + 'Arr',
                            handler: 'setFormattedMultiSelectValues',
                          }"
                          :data="field.lookups.map(i => i.value)"
                          autocomplete
                          field="value"
                          type="is-info"
                          :open-on-focus="true"
                          placeholder="Select one or multiple"
                          @typing="(val) => getCustomFieldFilteredTags(val, field)"
                          @input="setNewEditedValues(
                            $event, getCustomFieldLabel(column.label, 'Account')
                          )"
                        />
                      </div>
                    </div>
                  </b-field>
                </validation-provider>
              </div>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  <template
                    v-if="
                      customFieldsList.filter(
                        (item) => item.label === getCustomFieldLabel(column.label, 'Account')
                      )[0].type === 'CURRENCY'
                    "
                  >
                    {{ props.row[column.field] | currency }}
                  </template>
                  <template v-else>
                    {{ props.row[column.field] }}
                  </template>
                </span>
              </template>
            </template>

            <template v-else>
              {{ props.row[column.field] }}
            </template>
          </b-tooltip>
        </template>
      </b-table-column>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                type="is-info"
                size="is-small"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="accounts" />
      </template>
    </b-table>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="re-assign owners modal"
      aria-modal
    >
      <template>
        <reassign-modal-form
          modal-title="RE-ASSIGN OWNER"
          :route-name="routeName"
          v-bind="formProps"
          @close="closeModal"
        />
      </template>
    </b-modal>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateAccount"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import states from '@/static/states.json';

import {
  TableFooter, TableHeader, EmptyTable, ReassignModalForm, InlineEditingSavePrompt,
} from '@/components/Shared';

export default {
  name: 'AccountsTable',

  components: {
    TableFooter,
    TableHeader,
    EmptyTable,
    draggable,
    ReassignModalForm,
    InlineEditingSavePrompt,
    ValidationProvider,
  },

  data: () => ({
    states,
    selectedState: null,

    checkedRows: [],

    rowInEdit: {},

    total: 0,
    page: 1,
    perPage: 50,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    selectedAccountId: '',

    openSwitch: true,

    filters: {},

    exportFields: {},

    searchClause: '',

    isComponentModalActive: false,

    isSavePromptActive: false,

    savePromptOpen: false,

    formProps: {
      dataIDList: [],
    },
    columns: [
      {
        id: 1,
        label: 'Actions',
        field: 'actions',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      }, {
        id: 2,
        label: 'Account Name',
        field: 'name',
        sortable: true,
        searchable: true,
        searchField: 'name',
        exportField: 'name',
        sticky: false,
        visible: true,
      }, {
        id: 3,
        label: 'Customer No.',
        field: 'externalId',
        sortable: true,
        searchable: true,
        searchField: 'externalId',
        exportField: 'externalId',
        sticky: false,
        visible: true,
      }, {
        id: 4,
        label: 'Owners',
        field: 'owners',
        sortable: true,
        searchable: true,
        searchField: 'owners',
        exportField: 'exportOwners',
        sticky: false,
        visible: true,
      }, {
        id: 5,
        label: 'Contacts',
        field: 'contacts',
        sortable: true,
        searchable: true,
        searchField: 'contacts',
        exportField: 'contacts',
        sticky: false,
        visible: true,
      }, {
        id: 6,
        label: 'Type',
        field: 'accountType',
        sortable: true,
        searchable: true,
        searchField: 'accountType',
        exportField: 'accountType',
        sticky: false,
        visible: true,
      }, {
        id: 7,
        label: 'Last Lead Date',
        field: 'lastLeadDate',
        sortable: true,
        searchable: true,
        searchField: 'lastLeadDate',
        exportField: 'lastLeadDate',
        sticky: false,
        visible: true,
      }, {
        id: 8,
        label: 'Last Opportunity Date',
        field: 'lastOpportunityDate',
        sortable: true,
        searchable: true,
        searchField: 'lastOpportunityDate',
        exportField: 'lastOpportunityDate',
        sticky: false,
        visible: true,
      }, {
        id: 9,
        label: 'HDS Create Date',
        field: 'hdsCreateDate',
        sortable: true,
        searchable: true,
        searchField: 'hdsCreateDate',
        exportField: 'hdsCreateDate',
        sticky: false,
        visible: true,
      }, {
        id: 10,
        label: 'HDS Last Quote Date ',
        field: 'hdsLastQuoteDate',
        sortable: true,
        searchable: true,
        searchField: 'hdsLastQuoteDate',
        exportField: 'hdsLastQuoteDate',
        sticky: false,
        visible: true,
      }, {
        id: 11,
        label: 'HDS Last Order Date',
        field: 'hdsLastOrderDate',
        sortable: true,
        searchable: true,
        searchField: 'hdsLastOrderDate',
        exportField: 'hdsLastOrderDate',
        sticky: false,
        visible: true,
      }, {
        id: 12,
        label: 'HDS Last Invoice Date',
        field: 'hdsLastInvoiceDate',
        sortable: true,
        searchable: true,
        searchField: 'hdsLastInvoiceDate',
        exportField: 'hdsLastInvoiceDate',
        sticky: false,
        visible: true,
      }, {
        id: 13,
        label: 'Annual Revenue',
        field: 'annualRevenue',
        sortable: true,
        searchable: true,
        searchField: 'annualRevenue',
        exportField: 'annualRevenue',
        sticky: false,
        visible: false,
      }, {
        id: 14,
        label: 'Industry',
        field: 'industry',
        sortable: true,
        searchable: true,
        searchField: 'industry',
        exportField: 'industry',
        sticky: false,
        visible: false,
      }, {
        id: 15,
        label: 'No Of Employees',
        field: 'noOfEmployees',
        sortable: true,
        searchable: true,
        searchField: 'noOfEmployees',
        exportField: 'noOfEmployees',
        sticky: false,
        visible: false,
      }, {
        id: 16,
        label: 'City',
        field: 'city',
        sortable: true,
        searchable: true,
        searchField: 'city',
        exportField: 'city',
        sticky: false,
        visible: false,
      }, {
        id: 17,
        label: 'State',
        field: 'state',
        sortable: true,
        searchable: true,
        searchField: 'state',
        exportField: 'state',
        sticky: false,
        visible: false,
      }, {
        id: 18,
        label: 'Street 1',
        field: 'streetOne',
        sortable: true,
        searchable: true,
        searchField: 'streetOne',
        exportField: 'streetOne',
        sticky: false,
        visible: false,
      }, {
        id: 19,
        label: 'Street 2',
        field: 'streetTwo',
        sortable: true,
        searchable: true,
        searchField: 'streetTwo',
        exportField: 'streetTwo',
        sticky: false,
        visible: false,
      }, {
        id: 20,
        label: 'Street 3',
        field: 'streetThree',
        sortable: true,
        searchable: true,
        searchField: 'streetThree',
        exportField: 'streetThree',
        sticky: false,
        visible: false,
      }, {
        id: 21,
        label: 'Zip',
        field: 'zipCode',
        sortable: true,
        searchable: true,
        searchField: 'zipCode',
        exportField: 'zipCode',
        sticky: false,
        visible: false,
      }, {
        id: 22,
        label: 'Created On',
        field: 'createdAt',
        sortable: true,
        searchable: true,
        searchField: 'createdAt',
        exportField: 'createdAt',
        sticky: false,
        visible: false,
      }, {
        id: 23,
        label: 'Modified On',
        field: 'updatedAt',
        sortable: true,
        searchable: true,
        searchField: 'updatedAt',
        exportField: 'updatedAt',
        sticky: false,
        visible: false,
      },
      {
        id: 34,
        label: 'Active',
        field: 'isActive',
        sortable: true,
        searchable: false,
        searchField: 'isActive',
        exportField: 'isActive',
        sticky: false,
        visible: true,
      },
      {
        id: 24,
        label: 'custom_field_1',
        field: 'customField1',
        sortable: true,
        searchable: true,
        searchField: 'customField1',
        exportField: 'customField1',
        sticky: false,
        visible: false,
      },
      {
        id: 25,
        label: 'custom_field_2',
        field: 'customField2',
        sortable: true,
        searchable: true,
        searchField: 'customField2',
        exportField: 'customField2',
        sticky: false,
        visible: false,
      },
      {
        id: 26,
        label: 'custom_field_3',
        field: 'customField3',
        sortable: true,
        searchable: true,
        searchField: 'customField3',
        exportField: 'customField3',
        sticky: false,
        visible: false,
      },
      {
        id: 27,
        label: 'custom_field_4',
        field: 'customField4',
        sortable: true,
        searchable: true,
        searchField: 'customField4',
        exportField: 'customField4',
        sticky: false,
        visible: false,
      },
      {
        id: 28,
        label: 'custom_field_5',
        field: 'customField5',
        sortable: true,
        searchable: true,
        searchField: 'customField5',
        exportField: 'customField5',
        sticky: false,
        visible: false,
      },
      {
        id: 29,
        label: 'custom_field_6',
        field: 'customField6',
        sortable: true,
        searchable: true,
        searchField: 'customField6',
        exportField: 'customField6',
        sticky: false,
        visible: false,
      },
      {
        id: 30,
        label: 'custom_field_7',
        field: 'customField7',
        sortable: true,
        searchable: true,
        searchField: 'customField7',
        exportField: 'customField7',
        sticky: false,
        visible: false,
      },
      {
        id: 31,
        label: 'custom_field_8',
        field: 'customField8',
        sortable: true,
        searchable: true,
        searchField: 'customField8',
        exportField: 'customField8',
        sticky: false,
        visible: false,
      },
      {
        id: 32,
        label: 'custom_field_9',
        field: 'customField9',
        sortable: true,
        searchable: true,
        searchField: 'customField9',
        exportField: 'customField9',
        sticky: false,
        visible: false,
      },
      {
        id: 33,
        label: 'custom_field_10',
        field: 'customField10',
        sortable: true,
        searchable: true,
        searchField: 'customField10',
        exportField: 'customField10',
        sticky: false,
        visible: false,
      },
      {
        id: 34,
        label: 'Parent Account',
        field: 'parent',
        sortable: true,
        searchable: true,
        searchField: 'parent',
        exportField: 'parent',
        sticky: false,
        visible: false,
      }, {
        id: 35,
        label: 'Dependent Accounts',
        field: 'childAccounts',
        sortable: true,
        searchable: true,
        searchField: 'childAccounts',
        exportField: 'childAccounts',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

    isEditing: false,
    inEditingMode: false,
    selectedAccount: null,
  }),

  computed: {
    filteredStatesData() {
      return this.states.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.state.toLowerCase()) >= 0);
    },

    ...mapGetters({
      grid: 'Grids/getGrid',
      accountStatus: 'Lookups/getAccountStatuses',
      // accounts: 'DataGrids/getAccounts',
      pageMeta: 'DataGrids/getPageMeta',
      account: 'Accounts/getAccount',
      accountTypes: 'Lookups/getAccountTypes',
      loading: 'DataGrids/getLoading',
      industries: 'Lookups/getIndustries',
      noOfEmployees: 'Lookups/getNoOfEmployees',
      isWidgetsOpen: 'Settings/getIsWidgetsOpen',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    body() {
      return this.checkedRows.length >= 1 ? this.checkedRows : this.accounts;
    },

    accounts: {
      get() {
        return this.$store.state.DataGrids.accounts;
      },

      set(value) {
        return this.$store.commit('DataGrids/SET_ACCOUNTS', value);
      },
    },

    isActive: {
      get() {
        return this.$store.state.Accounts.account.isActive;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_IS_ACTIVE', value);
      },
    },

    streetOne: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.street1;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_STREET_1', value);
      },
    },

    streetTwo: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.street2;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_STREET_2', value);
      },
    },

    streetThree: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.street3;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_STREET_3', value);
      },
    },

    state: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.state;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_STATE', value);
      },
    },

    city: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.city;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_CITY', value);
      },
    },

    zip: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.zip;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_ZIP', value);
      },
    },

    industryId: {
      get() {
        return this.$store.state.Accounts.account.companyInformation.industryId;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_COMPANY_INFORMATION_INDUSTRY_ID', value);
      },
    },

    noOfEmployeesId: {
      get() {
        return this.$store.state.Accounts.account.companyInformation.noOfEmployeesId;
      },

      set(value) {
        return this.$store.commit(
          'Accounts/SET_ACCOUNT_COMPANY_INFORMATION_NO_OF_EMPLOYEES_ID', value,
        );
      },
    },

    typeId: {
      get() {
        return this.$store.state.Accounts.account.typeId;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_TYPE_ID', value);
      },
    },

    annualRevenue: {
      get() {
        return this.$store.state.Accounts.account.companyInformation.annualRevenue;
      },

      set(value) {
        return this.$store.commit(
          'Accounts/SET_ACCOUNT_COMPANY_INFORMATION_ANNUAL_REVENUE', value,
        );
      },
    },

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.accountCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_ACC_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Accounts.account.customFields;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_CUSTOM_FIELDS', value);
      },
    },

    // Check if a user is an admin
    isAdmin() {
      return this.isUserAdmin();
    },

    // Check if a user is a sales manager
    isSalesManager() {
      return this.isUserSalesManager();
    },

    routeName() {
      return this.$route && this.$route.name ? this.$route.name : '';
    },
  },

  watch: {
    rowInEdit: {
      handler(value) {
        this.$store.commit('Accounts/SET_ACCOUNT_OBJECT', value);
      },
    },

    perPage: {
      handler() {
        this.fetchAccounts();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },

    openSwitch: {
      handler() {
        this.fetchAccounts();
      },

      deep: true,
    },

    isComponentModalActive: {
      handler(value) {
        if (!value) {
          this.fetchAccounts();
        }
      },
      deep: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchGrid', {
        gridName: 'accounts',
        organizationId,
      });
      await this.$store.dispatch('Settings/fetchCustomFields', 'Account');

      const sortedColumnList = [...this.columns]
        .map((newItem) => {
          if (newItem.label.startsWith('custom')) {
            return ({ ...newItem, visible: true });
          }
          return newItem;
        })
        .filter((item) => (item.label.startsWith('custom')
          ? this.getCustomFieldLabel(item.label, 'Account') !== '' : true));
      this.columns = sortedColumnList;

      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'accounts',
          customizations: { ...this.customizations, isWidgetsOpen: this.isWidgetsOpen },
          filters: this.filters,
        });
      }
      await this.mapSavedFilters();
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    if (Object.keys(this.$route.query).length > 0 && this.$route.path.includes('/accounts')) {
      const payload = { ...this.$route.query };
      delete payload?.activeTab;
      this.filters = payload;
    }

    if (this.grid.columns && (!this.grid.filters || Object.keys(this.grid.filters).length === 0)) {
      this.fetchAccounts();
    }
    this.$store.dispatch('Lookups/fetchAccountStatuses');
    this.$store.dispatch('Lookups/fetchIndustries');
    this.$store.dispatch('Lookups/fetchNoOfEmployees');
    this.$store.dispatch('Lookups/fetchAccountTypes');
  },

  beforeUpdate() {
    if (localStorage.getItem('access_token') && this.$route.path.includes('/accounts')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'accounts',
        customizations: { ...this.customizations, isWidgetsOpen: this.isWidgetsOpen },
        filters: this.filters,
      });
    }
  },

  methods: {
    mapSavedFilters() {
      if (this.grid.filters) {
        this.$refs.accountsTable.filters = { ...this.grid.filters };
      }
      return '';
    },

    async setMultiSelectFields() {
      try {
        if (this.selectedAccountId && this.isEditing) {
          const customFields = [...this.customFields];
          const newCustomFields = customFields.map((it) => ({
            ...it,
            value: Array.isArray(it.value)
              ? it.value.join(',') : it.value,
          }));

          const newValue = {
            ...this.account,
            customFields: newCustomFields,
          };

          await this.setRowInEdit(newValue);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async setFormattedMultiSelectValues(index, field) {
      try {
        if (this.selectedAccountId && this.isEditing) {
          this.accounts = this.accounts.map((item, ind) => {
            if (index === ind && this.inEditingMode) {
              const newItem = { ...item };
              if (item[field]) {
                newItem[field] = item[field] && Array.isArray(item[field]) ? item[field] : item[field].split(' ');
              }
              return newItem;
            }
            return item;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async setNewEditedValues(event, label) {
      try {
        if (this.selectedAccountId && this.isEditing) {
          const customFields = [...this.customFields];
          const findField = customFields.findIndex((obj) => obj.label === label);
          const newObj = customFields[findField];
          customFields.splice(findField, 1, {
            ...newObj,
            value: Array.isArray(event)
              ? event.join(',') : event,
          });

          const newValue = {
            ...this.account,
            customFields,
          };

          await this.setRowInEdit(newValue);
        }
      } catch (error) {
        console.error(error);
      }
    },

    getCustomFieldFilteredTags(text, field) {
      this.filteredTags = field.lookups.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label.startsWith('custom')
          ? this.getCustomFieldLabel(el.label, 'Account') : el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label).sort();
      const userConfiguration = this.grid.columns.map((el) => el.label).sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    setSelectedState(value) {
      this.selectedState = value;
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedAccountId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex(
          (el) => el.label === a.label,
        ) - this.grid.columns.findIndex((el) => el.label === b.label),
      );

      const getVisibility = (fieldName) => {
        const column = this.grid.columns.find((el) => el.label === fieldName);
        if (column) {
          return column.visible;
        }
        return true;
      };

      const newColumns = this.columns.map((el) => ({
        ...el,
        visible: getVisibility(el.label),
      }));

      const customFieldExists = (fieldName) => {
        const data = JSON.parse(localStorage.getItem('custom_fields'));
        const customFields = [...data];
        return customFields.includes((el) => el.code === fieldName && el.objectName === 'Account');
      };

      const hasCustomField = (fieldName) => newColumns.includes(
        (el) => el.label === fieldName,
      );

      // check for customFields available & append
      if (!hasCustomField('custom_field_1') && customFieldExists('custom_field_1')) {
        newColumns.push(
          {
            id: 24,
            label: 'custom_field_1',
            field: 'customField1',
            sortable: true,
            searchable: true,
            searchField: 'customField1',
            exportField: 'customField1',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_2') && customFieldExists('custom_field_2')) {
        newColumns.push(
          {
            id: 25,
            label: 'custom_field_2',
            field: 'customField2',
            sortable: true,
            searchable: true,
            searchField: 'customField2',
            exportField: 'customField2',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_3') && customFieldExists('custom_field_3')) {
        newColumns.push(
          {
            id: 26,
            label: 'custom_field_3',
            field: 'customField3',
            sortable: true,
            searchable: true,
            searchField: 'customField3',
            exportField: 'customField3',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_4') && customFieldExists('custom_field_4')) {
        newColumns.push(
          {
            id: 27,
            label: 'custom_field_4',
            field: 'customField4',
            sortable: true,
            searchable: true,
            searchField: 'customField4',
            exportField: 'customField4',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_5') && customFieldExists('custom_field_5')) {
        newColumns.push(
          {
            id: 28,
            label: 'custom_field_5',
            field: 'customField5',
            sortable: true,
            searchable: true,
            searchField: 'customField5',
            exportField: 'customField5',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_6') && customFieldExists('custom_field_6')) {
        newColumns.push(
          {
            id: 29,
            label: 'custom_field_6',
            field: 'customField6',
            sortable: true,
            searchable: true,
            searchField: 'customField6',
            exportField: 'customField6',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_7') && customFieldExists('custom_field_7')) {
        newColumns.push(
          {
            id: 30,
            label: 'custom_field_7',
            field: 'customField7',
            sortable: true,
            searchable: true,
            searchField: 'customField7',
            exportField: 'customField7',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_8') && customFieldExists('custom_field_8')) {
        newColumns.push(
          {
            id: 31,
            label: 'custom_field_8',
            field: 'customField8',
            sortable: true,
            searchable: true,
            searchField: 'customField8',
            exportField: 'customField8',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_9') && customFieldExists('custom_field_9')) {
        newColumns.push(
          {
            id: 32,
            label: 'custom_field_9',
            field: 'customField9',
            sortable: true,
            searchable: true,
            searchField: 'customField9',
            exportField: 'customField9',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_10') && customFieldExists('custom_field_10')) {
        newColumns.push(
          {
            id: 33,
            label: 'custom_field_10',
            field: 'customField10',
            sortable: true,
            searchable: true,
            searchField: 'customField10',
            exportField: 'customField10',
            sticky: false,
            visible: true,
          },
        );
      } else if (hasCustomField('custom_field_1') && !customFieldExists('custom_field_1')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_1');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_2') && !customFieldExists('custom_field_2')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_2');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_3') && !customFieldExists('custom_field_3')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_3');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_4') && !customFieldExists('custom_field_4')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_4');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_5') && !customFieldExists('custom_field_5')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_5');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_6') && !customFieldExists('custom_field_6')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_6');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_7') && !customFieldExists('custom_field_7')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_7');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_8') && !customFieldExists('custom_field_8')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_8');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_9') && !customFieldExists('custom_field_9')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_9');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_10') && !customFieldExists('custom_field_10')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_10');
        newColumns.splice(index, 1);
      }

      this.columns = newColumns;
      this.customizations = this.grid.customizations;
      this.filters = this.grid.filters || {};
    },

    /**
     * fetchAccounts
     *
     */
    async fetchAccounts() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt).toISOString().substring(0, 10);
        }
      }

      if (this.openSwitch) {
        // TODO---  change isActive
        filters = { ...filters, isActive: true };
      }
      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, contactIds: this.$route.params.contactId };
      } else if (this.$route.name === 'ViewLead') {
        filters = { ...filters, opportunityId: this.$route.params.leadId };
      } else if (this.$route.name === 'ViewOpportunity') {
        filters = { ...filters, opportunityId: this.$route.params.opportunityId };
      } else if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('DataGrids/fetchAccounts', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
      } catch (error) {
        console.error(error);
      } finally {
        await this.$store.dispatch('DataGrids/fetchPageMeta', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
        this.total = this.pageMeta.totalElements;
      }
    },

    async exportAccounts() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt).toISOString().substring(0, 10);
        }
      }

      if (this.openSwitch) {
        // TODO---  change isActive
        filters = { ...filters, isActive: true };
      }
      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, contactIds: this.$route.params.contactId };
      } else if (this.$route.name === 'ViewLead') {
        filters = { ...filters, opportunityId: this.$route.params.leadId };
      } else if (this.$route.name === 'ViewOpportunity') {
        filters = { ...filters, opportunityId: this.$route.params.opportunityId };
      } else if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('Exports/fetchAccounts', {
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * @param {Number} page
     */
    onPageChange(page) {
      this.page = page;
      this.fetchAccounts();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchAccounts();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    async onFilter(filters) {
      this.filters = filters;
      await this.fetchAccounts();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.accountsTable.resetMultiSorting();
      this.$refs.accountsTable.filters = {};
      this.searchClause = '';
      this.fetchAccounts();
    },

    /**
     * searchAccount
     *
     */
    searchAccount(value) {
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchAccounts();
      }, 800);
    },

    /**
     * checkOwnership
     *
     * check if user is account owner
     * @param {object} account
     */
    checkOwnership(ownerIds) {
      if (ownerIds && ownerIds.split(',').length > 0) {
        return this.isAccountOwner(ownerIds.split(',')[0], ownerIds.split(',')[1]);
      }

      return this.isAccountOwner(0, 0);
    },

    /**
     * onEdit
     *
     * @param {object} account
     */
    async onEdit(account, props) {
      this.loading = true;
      try {
        if ((this.hasRole('ROLE_BUSINESS_ADMIN')
          || this.checkOwnership(account.ownerIds) || this.isTeammate(account.teamIds))) {
          await this.$store.dispatch('Accounts/fetchAccount', account.id);
          this.selectedAccountId = account.id;
          this.isEditing = true;

          const newValue = {
            ...this.account,
            customFields: this.customFieldsList.map((item) => {
              const sortedArr = this.account.customFields
                .filter((newItem) => newItem.customFieldId === item.id);
              if (sortedArr.length > 0) {
                return (
                  {
                    customFieldId: item.id,
                    customFieldEntryId: sortedArr.length > 0
                      ? sortedArr[0].customFieldEntryId : null,
                    label: item.label,
                    value: sortedArr[0].value && sortedArr[0].value.split(',').length > 1
                      ? sortedArr[0].value.split(',') : sortedArr[0].value,
                  }
                );
              }
              return (
                {
                  customFieldId: item.id,
                  customFieldEntryId: null,
                  label: item.label,
                  value: null,
                }
              );
            }),
          };
          await this.setRowInEdit(newValue);

          this.accounts = this.accounts.map((item, ind) => {
            if (props.index === ind) {
              const entries = Object.entries(item);
              entries.forEach((i) => {
                if (i[0].startsWith('customField') && i[1] !== null) {
                  entries.push([`${i[0]}Arr`, i[1].split(',').length > 1 ? i[1].split(',') : i[1]]);
                }
              });

              return {
                ...Object.fromEntries(entries),
              };
            }
            return item;
          });

          this.setMultiSelectFields();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.inEditingMode = true;
        this.$emit('finished-mapping');
      }
    },

    setRowInEdit(value) {
      this.rowInEdit = value;
      return this.rowInEdit;
    },

    /**
     * cancelAccountEdit
     *
     */
    cancelAccountEdit() {
      this.selectedAccount = null;
      this.isEditing = false;
    },

    /**
     * updateAccount
     *
     * @returns {Promise<JSON>}
     */
    async updateAccount() {
      const payload = { ...this.account };
      try {
        await this.$store.dispatch('Accounts/updateAccount', {
          accountId: this.selectedAccountId,
          account: payload,
        });
        await this.fetchAccounts();
        this.isEditing = false;
        this.selectedAccountId = '';
        this.$store.commit('Accounts/CLEAR_ACCOUNT');
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * cancelUpdate
     *
     * @returns {Promise<JSON>}
     */
    async cancelUpdate() {
      try {
        this.isEditing = false;
        this.selectedAccountId = '';
        this.$store.commit('Accounts/CLEAR_ACCOUNT');
        await this.fetchAccounts();
      } catch (error) {
        console.error(error);
      }
    },

    openModal() {
      this.isComponentModalActive = true;
      const checkedAccounts = this.checkedRows.length > 0 ? this.checkedRows : [];
      const accountIDList = checkedAccounts.map((account) => account.id);
      this.formProps.dataIDList = accountIDList;
    },

    async closeModal() {
      this.isComponentModalActive = false;

      /* START: FORCE PAGE RELOAD ON CLOSING MODAL */
      await this.fetchAccounts();
      setTimeout(async () => {
        await this.fetchAccounts();
      }, 300);
      /* END: FORCE PAGE RELOAD ON CLOSING MODAL */
    },
  },
};
</script>
