<template>
  <div class="card is-success is-spaced-top">
    <header class="card-header">
      <p class="card-header-title">
        company information
      </p>
    </header>

    <div class="card-content">
      <b-field
        label="Industry"
        label-for="industry"
        class="is-light"
      >
        <b-select
          id="industry"
          v-model="industryId"
          expanded
        >
          <option
            v-for="(industry, index) in industries"
            :key="index"
            :value="industry.id"
          >
            {{ industry.value }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="No. Of Employees"
        label-for="noOfEmployees"
        class="is-light"
      >
        <b-select
          id="noOfEmployees"
          v-model="noOfEmployeesId"
          expanded
        >
          <option
            v-for="(option, index) in noOfEmployees"
            :key="index"
            :value="option.id"
          >
            {{ option.value }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Annual Revenue"
        label-for="annualRevenue"
        class="is-light"
      >
        <currency-input
          id="annualRevenue"
          v-model="annualRevenue"
          class="input"
        />
      </b-field>
    </div>
  </div>
</template>

<script>
export default {

  name: 'AccountsCompanyInformationForm',

  props: {
    industries: {
      type: Array,
      required: true,
    },

    noOfEmployees: {
      type: Array,
      required: true,
    },
  },

  computed: {
    industryId: {
      get() {
        return this.$store.state.Accounts.account.companyInformation.industryId;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_COMPANY_INFORMATION_INDUSTRY_ID', value);
      },
    },

    noOfEmployeesId: {
      get() {
        return this.$store.state.Accounts.account.companyInformation.noOfEmployeesId;
      },

      set(value) {
        return this.$store.commit(
          'Accounts/SET_ACCOUNT_COMPANY_INFORMATION_NO_OF_EMPLOYEES_ID', value,
        );
      },
    },

    annualRevenue: {
      get() {
        return this.$store.state.Accounts.account.companyInformation.annualRevenue;
      },

      set(value) {
        return this.$store.commit(
          'Accounts/SET_ACCOUNT_COMPANY_INFORMATION_ANNUAL_REVENUE', value,
        );
      },
    },
  },

};
</script>

<style>

</style>
