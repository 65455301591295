<template>
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title">
        address information
      </p>
    </header>

    <div class="card-content">
      <fieldset>
        <b-field
          label="Street 1"
          label-for="street1"
          class="is-primary"
        >
          <b-input
            id="street1"
            v-model="street1"
          />
        </b-field>

        <b-field
          label="Street 2"
          label-for="street2"
          class="is-primary"
        >
          <b-input
            id="street2"
            v-model="street2"
          />
        </b-field>

        <b-field
          label="Street 3"
          label-for="street3"
          class="is-primary"
        >
          <b-input
            id="street3"
            v-model="street3"
          />
        </b-field>

        <div class="columns">
          <div class="column">
            <b-field
              label="City"
              label-for="city"
              class="is-primary"
            >
              <b-input
                id="city"
                v-model="city"
              />
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="State"
              label-for="state"
              class="is-primary"
            >
              <b-autocomplete
                id="state"
                v-model="state"
                field="name"
                :data="filteredStatesData"
                :open-on-focus="true"
                @select="setSelectedState"
              />
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="Zip"
              label-for="zip"
              class="is-primary"
            >
              <b-input
                id="zip"
                v-model="zip"
              />
            </b-field>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import states from '@/static/states.json';

export default {

  name: 'AccountsAddressInformationForm',

  data: () => ({
    states,
    selectedState: null,
  }),

  computed: {
    filteredStatesData() {
      return this.states.filter((option) => option.name
        .toString()
        .toLowerCase()
        .indexOf(this.state.toLowerCase()) >= 0);
    },

    street1: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.street1;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_STREET_1', value);
      },
    },

    street2: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.street2;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_STREET_2', value);
      },
    },

    street3: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.street3;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_STREET_3', value);
      },
    },

    state: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.state;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_STATE', value);
      },
    },

    city: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.city;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_CITY', value);
      },
    },

    zip: {
      get() {
        return this.$store.state.Accounts.account.addressInformation.zip;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_ADDRESS_INFORMATION_ZIP', value);
      },
    },

  },

  methods: {
    setSelectedState(value) {
      this.selectedState = value;
    },
  },
};
</script>

<style scoped>
</style>
