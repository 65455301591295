<template>
  <div>
    <table-header
      :is-drilldown="false"
      @export-pdf="exportPDF(columns, body, 'orderquotefinancials.pdf')"
      @export-xlsx="exportXLSX(body, 'orderquotefinancials.csv')"
    >
      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0"
          size="is-small"
          icon="power-off"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isBordered"
                size="is-small"
              >
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isCheckable"
                size="is-small"
              >
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isNarrowed"
                size="is-small"
              >
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.stickyHeaders"
                size="is-small"
              >
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isScrollable"
                size="is-small"
              >
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStriped"
                size="is-small"
              >
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
        >
          <b-button
            slot="trigger"
            icon-left="eye"
            size="is-small"
            icon-right="chevron-down"
          >
            columns
          </b-button>
          <draggable
            v-model="columns"
            group="columns"
            @start="drag=true"
            @end="drag=false"
          >
            <b-dropdown-item
              v-for="(column, index) in columns"
              :key="index"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                  size="is-small"
                >
                  {{ column.label }}
                </b-checkbox>
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="hedbergTable"
      :mobile-cards="false"
      :data="accountOrderQuoteFinancials"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.stickyHeaders"
      :scrollable="customizations.isScrollable"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <b-input
            v-model="props.filters[`${column.searchField}`]"
            placeholder="Search..."
            icon="search"
            size="is-small"
          />
        </template>

        <template v-slot="props">
          {{ props.row[column.field] }}
        </template>
      </b-table-column>

      <template slot="footer">
        <!-- TODO: Enable on next sprint -->
        <!-- <th
          v-for="(option, index) in Object.values(orderQuoteFinancialsSummation)"
          :key="index"
        >
          {{ option }}
        </th> -->
      </template>

      <template slot="empty">
        <empty-table table-name="opportunities" />
      </template>
    </b-table>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import { TableHeader, EmptyTable } from '@/components/Shared';

export default {
  name: 'AccountsHedbergTable',

  components: {
    TableHeader,
    EmptyTable,
    draggable,
  },

  data: () => ({
    columns: [
      {
        label: 'Backlog Sell',
        field: 'totalBacklogSell',
        searchField: 'backlogSell',
        searchable: true,
        sortable: true,
        visible: true,
      },
      {
        label: 'Backlog GP$',
        field: 'totalBacklogGp',
        searchField: 'backlogGP',
        searchable: true,
        sortable: true,
        visible: true,
      },
      {
        label: 'Backlog GP%',
        field: 'totalBacklogGpPercentage',
        searchField: 'backlogGrossProfitPercent',
        searchable: true,
        sortable: true,
        visible: true,
      },
      {
        label: 'YTD Booking Sell',
        field: 'bookingSellCy',
        searchField: 'ytdBookingSell',
        searchable: true,
        sortable: true,
        visible: true,
      },
      {
        label: 'YTD Booking GP$',
        field: 'bookingGpCy',
        searchField: 'ytdBookingGP',
        searchable: true,
        sortable: true,
        visible: true,
      },
      {
        label: 'YTD Booking GP%',
        field: 'bookingGpPercentageCy',
        searchField: 'ytdBookingGrossProfitPercent',
        searchable: true,
        sortable: true,
        visible: true,
      },
      {
        label: 'YTD Invoiced Sell',
        field: 'invoicedSellCy',
        searchField: 'ytdInvoicedSell',
        searchable: true,
        sortable: true,
        visible: true,
      },
      {
        label: 'YTD Invoiced GP$',
        field: 'invoicedGpCy',
        searchField: 'ytdInvoicedGP',
        searchable: true,
        sortable: true,
        visible: true,
      },
      {
        label: 'YTD Invoiced GP%',
        field: 'invoicedGpCyPercentage',
        searchField: 'ytdInvoicedGrossProfitPercent',
        searchable: true,
        sortable: true,
        visible: true,
      },
    ],

    checkedRows: [],

    total: 0,
    page: 1,
    perPage: 50,

    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    isEditing: false,
    selectedRecordId: null,

    filters: {},

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },
  }),

  computed: {
    ...mapGetters({
      account: 'Accounts/getAccountView',
      accountOrderQuoteFinancials: 'OrderQuoteFinancials/getAccountOrderQuoteFinancials',
      orderQuoteFinancialsSummation: 'OrderQuoteFinancials/getOrderQuoteFinancialsSummation',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },
  },

  async created() {
    // await this.fetchOrderQuoteFinancials();
    // this.$store.dispatch(
    //   'OrderQuoteFinancials/fetchOrderQuoteFinancialsSummation',
    //   this.$route.params.accountId,
    // );
  },

  methods: {
    async fetchOrderQuoteFinancials() {
      try {
        if (this.account.externalId) {
          await this.$store.dispatch(
            'OrderQuoteFinancials/fetchAccountOrderQuoteFinancials',
            this.account.externalId,
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    getTotal(field) {
      const sum = this.accountOrderQuoteFinancials
        .map((el) => el[field])
        .reduce((a, b) => a + b, 0);
      return sum;
    },
  },
};
</script>

<style>

</style>
