<template>
  <div>
    <table-header
      :fields="exportFields"
      :name="'documents'"
      @export-xls="exportDocuments"
      @search="searchDocument"
    >
      <template #update-button>
        <b-button
          v-if="isEditing"
          ref="saveEntryButton"
          icon-left="content-save"
          class="mr-4"
          type="is-success"
          size="is-small"
          @click="updateDocument"
        >
          save
        </b-button>
        <b-button
          v-if="isEditing"
          ref="cancelEntryButton"
          icon-left="close"
          type="is-success"
          size="is-small"
          @click="cancelUpdate"
        >
          cancel
        </b-button>
      </template>

      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0"
          size="is-small"
          icon="power-off"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox v-model="customizations.isBordered">
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox v-model="customizations.isCheckable">
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox v-model="customizations.isNarrowed">
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox v-model="customizations.isStickyHeaders">
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox v-model="customizations.isScrollable">
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox v-model="customizations.isStriped">
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
          class="dg-columns"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="eye"
            icon-right="chevron-down"
          >
            columns
          </b-button>
          <draggable
            v-model="columns"
            group="columns"
            handle=".handle"
            @start="drag = true"
            @end="drag = false"
          >
            <b-dropdown-item
              v-for="column in columns"
              :key="column.id"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                >
                  {{ column.label }}
                </b-checkbox>
                <i class="grip-vertical handle" />
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="documentsTable"
      v-click-outside-dg="onClickOutsideTable"
      :mobile-cards="false"
      :loading="loading"
      :data="documents.content"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @update:selected="onClickOutsideEditing"
    >
      <template v-for="(column, index) in columns">
        <b-table-column
          :key="index"
          :custom-key="index"
          :label="column.label"
          :field="column.field"
          :searchable="column.searchable"
          :sortable="column.sortable"
          :visible="column.visible"
        >
          <template
            slot="searchable"
            slot-scope="props"
          >
            <template>
              <b-input
                v-show="
                  column.searchField === 'createdAt' ||
                    column.searchField === 'updatedAt'
                "
                v-model="props.filters[column.searchField]"
                type="date"
                size="is-small"
                icon="calendar-month"
              />

              <b-input
                v-show="
                  column.searchField === 'originalFileName' ||
                    column.searchField === 'createdByName' ||
                    column.searchField === 'updatedByName'
                "
                v-model="props.filters[`${column.searchField}`]"
                size="is-small"
                placeholder="Search..."
                icon="search"
              />
            </template>
          </template>

          <template v-slot="props">
            <template v-if="column.field === 'actions'">
              <div
                v-if="readOnly === false"
                class="buttons"
              >
                <b-tooltip
                  type="is-primary"
                  label="Download"
                  position="is-bottom"
                >
                  <b-button
                    type="is-text"
                    class="has-text-grey"
                    icon-right="download"
                    @click="
                      downloadDocument({
                        fileName: props.row.originalFileName,
                        documentId: props.row.id,
                      })
                    "
                  />
                </b-tooltip>

                <b-tooltip
                  type="is-primary"
                  label="Delete"
                  position="is-bottom"
                >
                  <b-button
                    type="is-text"
                    class="has-text-grey"
                    icon-right="trash-can"
                    @click="deleteDocument(props.row.id)"
                  />
                </b-tooltip>
              </div>
              <div v-else />
            </template>

            <b-tooltip
              v-else
              label="Double-click to edit"
              class="is-info"
            >
              <template v-if="column.field === 'originalFileName'">
                <b-field v-if="isEditing && props.row.id === selectedDocumentId">
                  <b-input
                    v-model="fileName"
                    size="is-small"
                  />
                </b-field>

                <span
                  v-else
                  class="has-text-info is-pointer has-text-weight-bold"
                  @dblclick="onEdit(props.row)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>

              <template
                v-else-if="
                  column.field === 'createdAt' || column.field === 'updatedAt'
                "
              >
                {{ props.row[column.field] | date }}
              </template>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="has-text-info has-text-weight-bold"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>
            </b-tooltip>
          </template>
        </b-table-column>
      </template>
      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                type="is-info"
                size="is-small"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="documents" />
      </template>
    </b-table>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateDocument"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import {
  TableFooter, TableHeader, EmptyTable, InlineEditingSavePrompt,
} from '@/components/Shared';

export default {
  name: 'DocumentsTable',

  components: {
    TableFooter,
    TableHeader,
    EmptyTable,
    draggable,
    InlineEditingSavePrompt,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    checkedRows: [],
    total: 0,
    page: 1,
    perPage: 50,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],
    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    isSavePromptActive: false,
    savePromptOpen: false,

    filters: {},
    exportFields: {},
    columns: [
      {
        id: '1',
        label: 'Document Title',
        field: 'originalFileName',
        sortable: true,
        searchable: true,
        searchField: 'originalFileName',
        exportField: 'originalFileName',
        sticky: false,
        visible: true,
      },
      {
        id: '2',
        label: 'Created By',
        field: 'createdByName',
        sortable: true,
        searchable: true,
        searchField: 'createdByName',
        exportField: 'createdByName',
        sticky: false,
        visible: true,
      },
      {
        id: '3',
        label: 'Created On',
        field: 'createdAt',
        sortable: true,
        searchable: true,
        searchField: 'createdAt',
        exportField: 'createdAt',
        sticky: false,
        visible: true,
      },
      {
        id: '4',
        label: 'Modified By',
        field: 'updatedByName',
        sortable: true,
        searchable: true,
        searchField: 'updatedByName',
        exportField: 'updatedByName',
        sticky: false,
        visible: true,
      },
      {
        id: '5',
        label: 'Modified On',
        field: 'updatedAt',
        sortable: true,
        searchable: true,
        searchField: 'updatedAt',
        exportField: 'updatedAt',
        sticky: false,
        visible: true,
      },
      {
        id: '6',
        label: 'Actions',
        field: 'actions',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
    ],

    isEditing: false,
    selectedDocumentId: '',

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },
  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getGrid',
      documents: 'DataGrids/getDocuments',
      loading: 'DataGrids/getLoading',
      file: 'Documents/getFile',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    body() {
      return this.checkedRows.length >= 1 ? this.checkedRows : this.documents;
    },

    fileName: {
      get() {
        return this.$store.state.Documents.file.fileName;
      },

      set(value) {
        return this.$store.commit('Documents/SET_FILE_FILENAME', value);
      },
    },
  },

  watch: {
    perPage: {
      handler() {
        this.fetchDocuments();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchGrid', {
        gridName: 'documents',
        organizationId,
      });
      if (response) {
        this.setGrid();
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'documents',
          customizations: this.customizations,
          filters: this.filters,
        });
      }
      await this.mapSavedFilters();
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    if (this.grid.columns && (!this.grid.filters || Object.keys(this.grid.filters).length === 0)) {
      this.fetchDocuments();
    }
  },

  beforeUpdate() {
    if (localStorage.getItem('access_token') && this.$route.path.includes('/documents')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'documents',
        customizations: this.customizations,
        filters: this.filters,
      });
    }
  },

  methods: {
    mapSavedFilters() {
      if (this.grid.filters) {
        this.$refs.documentsTable.filters = { ...this.grid.filters };
      }
      return '';
    },

    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedDocumentId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map((el, index) => ({
        ...el,
        visible: this.grid.columns[index].visible,
      }));
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
      this.filters = this.grid.filters || {};
    },

    /**
     * fetchDocuments
     *
     */
    async fetchDocuments() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach(
          (key) => filters[key] === '' && delete filters[key],
        );
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt)
            .toISOString()
            .substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt)
            .toISOString()
            .substring(0, 10);
        }
      }

      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, contactId: this.$route.params.contactId };
      } if (this.$route.name === 'ViewLead') {
        filters = { ...filters, opportunityId: this.$route.params.leadId };
      } if (this.$route.name === 'ViewOpportunity') {
        filters = { ...filters, opportunityId: this.$route.params.opportunityId };
      } if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('DataGrids/fetchDocuments', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters:
            filters && Object.keys(filters).length > 0
              ? `&${new URLSearchParams(filters).toString()}`
              : '',
        });
        this.total = this.documents.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    async exportDocuments() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach(
          (key) => filters[key] === '' && delete filters[key],
        );
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt)
            .toISOString()
            .substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt)
            .toISOString()
            .substring(0, 10);
        }
      }

      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, contactId: this.$route.params.contactId };
      } if (this.$route.name === 'ViewLead') {
        filters = { ...filters, opportunityId: this.$route.params.leadId };
      } if (this.$route.name === 'ViewOpportunity') {
        filters = { ...filters, opportunityId: this.$route.params.opportunityId };
      } if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('Exports/fetchDocuments', {
          sort: [this.sortField, this.sortOrder],
          filters:
            filters && Object.keys(filters).length > 0
              ? `&${new URLSearchParams(filters).toString()}`
              : '',
        });
      } catch (error) {
        console.error(error);
      }
    },

    async deleteDocument(payload) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Document',
        message:
          'Are you sure you want to <b>delete</b> this document? This action cannot be undone.',
        confirmText: 'Delete Document',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Documents/deleteDocument', payload);
            await this.fetchDocuments();
          } catch (error) {
            console.error(error);
          }
        },
      });
    },

    /**
     * onPageChange
     *
     * @param {Number} page - next page number
     */
    onPageChange(page) {
      this.page = page;
      this.fetchDocuments();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchDocuments();
    },

    /**
     * onFilter
     *
     * @param {object} filter
     */
    onFilter(filter) {
      this.filters = filter;
      this.fetchDocuments();
    },

    /**
     * onEdit
     *
     * @param {object} document
     */
    onEdit(document) {
      this.selectedDocumentId = document.id;
      this.$store.commit('Documents/SET_FILE', {
        documentId: document.id,
        fileName: document.originalFileName,
      });
      this.isEditing = true;
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.documentsTable.resetMultiSorting();
      this.$refs.documentsTable.filters = {};
      this.filters = {};
      this.fetchDocuments();
    },

    /**
     * updateDocument
     *
     */
    async updateDocument() {
      const payload = { ...this.file };
      try {
        await this.$store.dispatch('Documents/updateDocument', payload);
        await this.fetchDocuments();
        this.isEditing = false;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * cancelUpdate
     *
     */
    async cancelUpdate() {
      try {
        await this.fetchDocuments();
        this.isEditing = false;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * searchDocument
     *
     * @param {String} keyword - search clause
     */
    async searchDocument(keyword) {
      try {
        const response = await this.$store.dispatch(
          'Documents/searchDocument',
          {
            page: this.page,
            perPage: this.perPage,
            keyword,
          },
        );
        this.total = response.totalElements;
        // eslint-disable-next-line func-names
        setTimeout(function () {
          this.searchDocument();
        }, 5000);
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * downloadDocument
     *
     * @param {object} payload
     */
    async downloadDocument(payload) {
      try {
        // if (payload.savedFileName === null) {
        //   this.$buefy.snackbar.open({
        //     duration: 5000,
        //     message: 'Document not found. Please re-upload the document.',
        //     type: 'is-danger',
        //   });
        // } else {
        await this.$store.dispatch('Documents/downloadDocument', payload);
        // }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
