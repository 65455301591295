<template>
  <validation-observer
    ref="leadsForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(updateLead)">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="false"
      />

      <!-- page header component -->
      <page-header
        :name="lead.name"
        icon="star"
      >
        <template #actions>
          <!-- TODO: Setup on dev env -->
          <!-- <div class="level-item">
            <b-field>
              <b-switch
                v-model="isActive"
                type="is-info"
              >
                active
              </b-switch>
            </b-field>
          </div> -->

          <div class="level-item">
            <b-field>
              <b-button
                :disabled="invalid"
                type="is-success"
                icon-left="content-save"
                native-type="submit"
              >
                update lead
              </b-button>
            </b-field>
          </div>
        </template>
      </page-header>

      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-three-quarters">
              <leads-form
                :lead-stages="leadStatuses"
                :temperatures="temperatures"
                :industries="industries"
                :lead-sources="leadSources"
                :no-of-employees="noOfEmployees"
              />
            </div>

            <div
              v-if="lead.stage && lead.stage.code !== 'closed_converted'"
              class="column"
            >
              <div class="content">
                <b-button
                  type="is-primary"
                  icon-left="bullseye"
                  @click="convertToOpportunity"
                >
                  Convert To Opportunity
                </b-button>
              </div>
            </div>
          </div>

          <b-field>
            <b-button
              :disabled="invalid"
              type="is-success"
              icon-left="content-save"
              value="update lead"
              native-type="submit"
            >
              update lead
            </b-button>
          </b-field>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import {
  LeadsForm,
} from '@/components/Leads';
import { PageHeader } from '@/components/Shared';

import LeadsMixin from '@/mixins/LeadsMixin';

export default {
  name: 'AddLead',

  components: {
    ValidationObserver,
    LeadsForm,
    PageHeader,
  },

  mixins: [LeadsMixin],

  computed: {
    isActive: {
      get() {
        return this.$store.state.Leads.lead.isActive;
      },

      set(value) {
        return this.$store.commit('Leads/SET_LEAD_IS_ACTIVE', value);
      },
    },
  },

  methods: {
    async updateLead() {
      this.isLoading = true;
      const payload = { ...this.lead };

      payload.tags = this.$store.state.Leads.lead.tags;

      if (payload.tags) {
        const tags = payload.tags.map((t) => t.tag || t);
        const uniqueTags = [...new Set(tags)]; // remove duplicate tags
        payload.tags = uniqueTags;
      } else {
        payload.tags = [];
      }

      payload.leadTypes = [...payload.leadTypes].map((i) => ({
        lookupId: i,
      }));

      if (payload.owner.opportunityOwnerId) {
        await this.$store.dispatch('Opportunities/updateOpportunityOwner', {
          opportunityId: this.$route.params.leadId,
          id: payload.owner.opportunityOwnerId,
          ownerId: payload.owner.id,
          percentage: payload.owner.percentage,
        });
      }

      if (!payload.coOwner.opportunityOwnerId && payload.coOwner.id) {
        await this.$store.dispatch('Opportunities/createOpportunityOwner', {
          opportunityId: this.$route.params.leadId,
          owner: payload.coOwner,
        });
      }

      if (payload.coOwner.opportunityOwnerId && payload.coOwner.id) {
        await this.$store.dispatch('Opportunities/updateOpportunityOwner', {
          opportunityId: this.$route.params.leadId,
          id: payload.coOwner.opportunityOwnerId,
          ownerId: payload.coOwner.id,
          percentage: payload.coOwner.percentage,
        });
      } else if (this.$route.name === 'AddOpportunity' && payload.coOwner.id) {
        await this.$store.dispatch('Opportunities/createOpportunityOwner', {
          opportunityId: this.$route.params.leadId,
          owner: payload.coOwner,
        });
      }

      try {
        const response = await this.$store.dispatch('Leads/updateLead', {
          leadId: this.$route.params.leadId,
          lead: payload,
        });
        if (payload.stageId === 145) {
          this.convertToOpportunity();
        } else {
          await this.$router.push(`/leads/${response.id}/view`);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async convertToOpportunity() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch(
          'Leads/convertToOpportunity',
          this.$route.params.leadId,
        );
        await this.$router.push(`/opportunities/${response.id}/edit`);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>

</style>
