<template>
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="link" />
        share link
      </p>

      <b-button
        icon-right="close"
        type="is-text"
        class="has-text-info"
        @click="$emit('close')"
      />
    </header>

    <div class="card-content">
      <form @submit.prevent="$emit('share-document', userId)">
        <div class="columns">
          <div class="column is-three-qurater">
            <b-field
              label="Send To"
              class="is-primary"
              label-for="sendTo"
            >
              <b-autocomplete
                id="sendTo"
                v-model="user"
                icon-right="search"
                field="fullName"
                size="is-small"
                :data="usersSearchResults"
                @typing="getUsersAsyncData"
                @select="setSelectedUser"
              >
                <template slot-scope="user">
                  <div class="media">
                    <div class="media-content">
                      <span class="is-capitalized">
                        {{ `${user.option.firstName} ${user.option.lastName}` }}
                      </span>
                      <br>
                      <small>
                        email: {{ user.option.email }} <br>
                        Phone: {{ user.option.phoneCell }}
                      </small>
                    </div>
                  </div>
                </template>

                <template #empty>
                  No results for {{ user }}
                </template>
              </b-autocomplete>
            </b-field>
          </div>
        </div>

        <b-field
          label="URL"
          class="is-primary"
        >
          <b-input
            ref="documentURL"
            :value="`https://www.mxc.com/documents/${file.fileName}`"
          />
          <p class="control">
            <b-button
              type="is-info"
              icon-left="clipboard"
              @click="copyURL"
            >
              copy
            </b-button>
          </p>
        </b-field>

        <b-field>
          <b-button
            type="is-success"
            native-type="submit"
          >
            send notification
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SearchMixin from '@/mixins/SearchMixin';

export default {

  name: 'ShareLinkModal',

  mixins: [SearchMixin],

  data: () => ({
    userId: '',
    user: '',
  }),

  computed: {
    ...mapGetters({
      users: 'Users/getUsers',
      file: 'Documents/getFile',
    }),
  },

  async created() {
    await this.$store.dispatch('Users/fetchUsers');
  },

  methods: {
    setSelectedUser(user) {
      this.userId = user.userId;
    },

    copyURL() {
      const URL = this.$refs.documentURL;
      URL.select();
      URL.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },
  },

};
</script>

<style>

</style>
