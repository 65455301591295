<template>
  <div>
    <!-- page header component -->
    <page-header
      name="leads"
      icon="star"
    >
      <template #actions>
        <div class="level-item">
          <search-bar />
        </div>

        <div class="level-item">
          <b-dropdown aria-role="list">
            <b-button
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>
            <template v-if="hasPermissions('create_accounts')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
              >
                <router-link to="/accounts/add">
                  Account
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_contacts')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
              >
                <router-link to="/contacts/add">
                  Contact
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
              >
                <router-link to="/leads/add">
                  Lead
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                has-link
                aria-role="listitem"
              >
                <router-link to="/opportunities/add">
                  Opportunity
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_activities')"
              has-link
              aria-role="menuitem"
            >
              <router-link to="/activities/add">
                Activity
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <widget-collapse-drawer>
      <template #breadcrumb>
        <div class="container">
          <b-breadcrumb size="is-small">
            <b-breadcrumb-item
              tag="router-link"
              to="/profile"
            >
              Home
            </b-breadcrumb-item>
            <b-breadcrumb-item
              tag="router-link"
              to="/leads"
              active
            >
              Leads
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <section class="section has-background-light">
        <div class="container">
          <div class="columns">
            <div class="column is-one-third">
              <open-leads-by-source @open-drilldown="openDrilldown" />
            </div>

            <div class="column is-one-third">
              <open-leads-by-account @open-drilldown="openDrilldown" />
            </div>

            <div class="column is-one-third">
              <open-leads-by-stage @open-drilldown="openDrilldown" />
            </div>
          </div>
        </div>
      </section>
    </widget-collapse-drawer>

    <section class="section">
      <div class="container">
        <b-tabs v-model="activeTab">
          <b-tab-item label="overview">
            <leads-table @convert-to-opportunity="convertToOpportunity" />
          </b-tab-item>

          <b-tab-item
            :visible="showDrilldown"
            label="drilldown"
          >
            <leads-drilldown-table :is-drilldown-active="showDrilldown" />
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import {
  LeadsTable,
} from '@/components/Leads';

import {
  OpenLeadsByAccount, OpenLeadsByStage, OpenLeadsBySource,
} from '@/components/Graphs';
import { PageHeader, WidgetCollapseDrawer } from '@/components/Shared';
import { SearchBar } from '@/components/Search';
import { LeadsDrilldownTable } from '@/components/DrilldownTables';

export default {
  name: 'ListLeads',

  components: {
    LeadsTable,
    PageHeader,
    SearchBar,
    OpenLeadsByAccount,
    OpenLeadsByStage,
    OpenLeadsBySource,
    LeadsDrilldownTable,
    WidgetCollapseDrawer,
  },

  data: () => ({
    activeTab: 0,
    showDrilldown: false,
  }),

  watch: {
    activeTab: {
      handler(value) {
        if (value === 0) {
          this.$router.replace('/leads');
          this.showDrilldown = false;
        }
      },

      deep: true,
    },
  },

  mounted() {
    if (this.hasRole('ROLE_BUSINESS_ADMIN') || this.hasRole('ROLE_SALES_MANAGER')) {
      this.$store.dispatch('Users/fetchSubordinates');
      this.$store.dispatch('Graphs/fetchTeams');
      this.$store.dispatch('Graphs/fetchDivisions');
    }

    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      this.showDrilldown = true;
      this.activeTab = 1;
    }
  },

  methods: {
    openDrilldown(value) {
      this.showDrilldown = true;
      this.activeTab = 1;
      this.$router.replace({ query: value.filters });
    },

    async convertToOpportunity(leadId) {
      try {
        const response = await this.$store.dispatch('Leads/convertToOpportunity', leadId);
        await this.$router.push(`/opportunities/${response.id}/edit`);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
