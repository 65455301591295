<template>
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title is-uppercase">
        <b-icon
          icon="account-plus-outline"
          type="is-white"
        />
        <span class="is-padded-left">new contact</span>
      </p>

      <b-button
        icon-right="close"
        type="is-text"
        class="has-text-info"
        @click="$emit('close')"
      />
    </header>

    <div class="card-content">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="false"
        :can-cancel="false"
      />
      <!-- contact form modal component -->
      <validation-observer
        ref="contactForm"
        v-slot="{ invalid, handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(createContact)">
          <div class="columns">
            <div class="column is-half">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="first name"
              >
                <b-field
                  label="First Name"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="firstName" />
                </b-field>
              </validation-provider>
            </div>

            <div class="column">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="last name"
              >
                <b-field
                  label="Last Name"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="lastName" />
                </b-field>
              </validation-provider>
            </div>
          </div>

          <validation-provider
            v-slot="{ errors, valid }"
            rules="required|email"
            name="email address"
          >
            <b-field
              label="Email"
              class="is-primary is-required"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="emailAddress"
                type="email"
              />
            </b-field>
          </validation-provider>

          <div class="columns">
            <div class="column">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="phone"
              >
                <b-field
                  label="Phone"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <div class="control is-medium">
                    <vue-tel-input
                      v-model="phone"
                      class="is-primary"
                    />
                  </div>
                </b-field>
              </validation-provider>
            </div>
          </div>

          <!-- <div class="columns">
            <div class="column">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="work phone"
              >
                <b-field
                  label="Cell"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <div class="control is-medium">
                    <vue-tel-input
                      v-model="phoneWork"
                      class="is-primary"
                    />
                  </div>
                </b-field>
              </validation-provider>
            </div>
          </div> -->

          <b-field>
            <div class="buttons is-spaced-top">
              <b-button
                type="is-success"
                :disabled="invalid"
                native-type="submit"
              >
                save
              </b-button>

              <b-button
                type="is-info"
                @click="$emit('close')"
              >
                cancel
              </b-button>
            </div>
          </b-field>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ContactsFormModal',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    moduleName: {
      type: String,
      required: true,
    },
    selectedAccount: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    isLoading: false,
  }),

  computed: {
    ...mapGetters({
      contact: 'Contacts/getContact',
      lead: 'Leads/getLead',
      opportunity: 'Opportunities/getOpportunity',
    }),

    firstName: {
      get() {
        return this.$store.state.Contacts.contact.firstName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_FIRST_NAME', value);
      },
    },

    lastName: {
      get() {
        return this.$store.state.Contacts.contact.lastName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_LAST_NAME', value);
      },
    },

    emailAddress: {
      get() {
        return this.$store.state.Contacts.contact.emailAddress;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_EMAIL_ADDRESS', value);
      },
    },

    phone: {
      get() {
        return this.$store.state.Contacts.contact.phone;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_PHONE', value);
      },
    },

    // phoneWork: {
    //   get() {
    //     return this.$store.state.Contacts.contact.phoneWork;
    //   },
    //   set(value) {
    //     return this.$store.commit('Contacts/SET_CONTACT_WORK_PHONE', value);
    //   },
    // },
  },

  beforeDestroy() {
    this.$store.commit('Contacts/CLEAR_CONTACT');
  },

  methods: {
    async createContact() {
      this.isLoading = true;

      if (this.moduleName === 'leads') {
        this.$store.commit(
          'Contacts/SET_CONTACT_ACCOUNT_ID',
          this.lead.accountId,
        );
      } else {
        this.$store.commit(
          'Contacts/SET_CONTACT_ACCOUNT_ID',
          this.opportunity.accountId,
        );
      }

      const payload = { ...this.contact };

      try {
        const response = await this.$store.dispatch(
          'Contacts/createContact',
          payload,
        );

        if (this.moduleName === 'leads') {
          this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT_ID', response.id);
          this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT', `${response.firstName} ${response.lastName}`);
        } else {
          this.$store.commit(
            'Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID',
            response.id,
          );
          this.$store.commit(
            'Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT',
            `${response.firstName} ${response.lastName}`,
          );
        }

        this.$emit('close');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }

      return null;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
