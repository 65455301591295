<template>
  <fieldset>
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="name"
    >
      <b-field
        horizontal
        label="Account Name"
        label-for="accountName"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-autocomplete
          id="accountName"
          v-model="name"
          field="name"
          icon-right="search"
          :loading="isLoading"
          :data="accountsSearchResults"
          @typing="getAccountsAsyncData"
          @select="(option) => (selectedAccount = option)"
        >
          <template slot-scope="props">
            <div class="media">
              <div class="media-content">
                {{ props.option.name }}
                <br>
                <small>
                  customer number: {{ props.option.externalId }}
                </small>
              </div>
            </div>
          </template>

          <template #empty>
            No results for {{ name }}
          </template>
        </b-autocomplete>
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="type"
    >
      <b-field
        horizontal
        label="Account Type"
        label-for="accountType"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <b-select
          id="accountType"
          v-model="typeId"
          expanded
        >
          <option
            v-for="(accountType, index) in accountTypes"
            :key="index"
            :value="accountType.id"
          >
            {{ accountType.value }}
          </option>
        </b-select>
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="max:200"
      name="description"
    >
      <b-field
        horizontal
        label="Description"
        label-for="description"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <b-input
          id="description"
          v-model="description"
          type="textarea"
        />
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      :rules="isDefaultType ? 'required' : ''"
      name="owner"
    >
      <b-field
        horizontal
        label="Owner"
        label-for="owner"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        :class="isDefaultType ? 'is-required' : ''"
      >
        <div class="columns">
          <div class="column is-half">
            <b-autocomplete
              id="owner"
              v-model="owner"
              icon-right="search"
              expanded
              field="fullName"
              :loading="isLoading"
              :data="ownerSearchResults"
              @typing="getOwnersAsyncData"
              @select="(option) => (selectedOwner = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.fullName.trim() }}
                    <br>
                    <small>
                      email: {{ props.option.email }} <br>
                      Phone: {{ props.option.phoneCell }}
                    </small>
                  </div>
                </div>
              </template>

              <template #empty>
                No results for {{ owner }}
              </template>
            </b-autocomplete>
          </div>

          <div class="column is-narrow">
            <div
              v-if="owner"
              @click="clearOwner"
            >
              <b-button
                aria-label="Clear Owner"
                type="is-danger"
                icon-right="close"
              />
            </div>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="CO-Owner"
      label-for="coOwner"
    >
      <div class="columns">
        <div class="column is-half">
          <b-autocomplete
            id="coOwner"
            v-model="coOwner"
            icon-right="search"
            expanded
            field="fullName"
            :loading="isLoading"
            :data="coOwnerSearchResults"
            @typing="getCoOwnersAsyncData"
            @select="(option) => (selectedCoOwner = option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.fullName.trim() }}
                  <br>
                  <small>
                    email: {{ props.option.email }} <br>
                    Phone: {{ props.option.phoneCell }}
                  </small>
                </div>
              </div>
            </template>

            <template #empty>
              No results for {{ coOwner }}
            </template>
          </b-autocomplete>
        </div>
        <div class="column is-narrow">
          <div
            v-if="coOwner"
            @click="clearCoOwner"
          >
            <b-button
              aria-label="Clear Co-Owner"
              type="is-danger"
              icon-right="close"
            />
          </div>
        </div>
      </div>
    </b-field>

    <b-field
      horizontal
      label="% Split"
    >
      <div class="columns is-vcentered">
        <div class="column">
          <validation-provider
            v-slot="{ errors }"
            rules="integer|max_value:100"
            name="owner percentage"
          >
            <b-field
              label="Owner"
              label-for="ownerPercentage"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
            >
              <b-input
                id="ownerPercentage"
                v-model="ownerPercentage"
                min="0"
                max="100"
                :disabled="!coOwnerId"
              />
            </b-field>
          </validation-provider>
        </div>

        <div class="column">
          <b-field label-for="percentageSlider">
            <b-slider
              id="percentageSlider"
              v-model="percentage"
              aria-label="Percentage Slider"
              :min="0"
              :max="100"
              type="is-info"
              :disabled="!coOwnerId"
            />
          </b-field>
        </div>

        <div class="column">
          <validation-provider
            v-slot="{ errors }"
            rules="integer|max_value:100"
            name="co owner percentage"
          >
            <b-field
              label="Co-Owner"
              label-for="coOwnerPercentage"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
            >
              <b-input
                id="coOwnerPercentage"
                v-model="coOwnerPercentage"
                min="0"
                max="100"
                :disabled="!coOwnerId"
              />
            </b-field>
          </validation-provider>
        </div>
      </div>
    </b-field>

    <b-field
      horizontal
      label="Website"
      label-for="website"
    >
      <b-input
        id="website"
        v-model="website"
      />
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      rules="required"
      name="phone"
    >
      <b-field
        horizontal
        label="Phone"
        label-for="phone"
        class="is-required"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
      >
        <vue-tel-input
          id="phone"
          v-model="phone"
        />
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="Fax"
      label-for="fax"
    >
      <b-input
        id="fax"
        v-model="fax"
      />
    </b-field>

    <b-field
      horizontal
      label="D&amp;B Company"
      label-for="dAndBCompany"
    >
      <b-input
        id="dAndBCompany"
        v-model="dandBCompany"
      />
    </b-field>

    <b-field
      horizontal
      label="DUNS Number"
      label-for="dunsNumber"
    >
      <b-input
        id="dunsNumber"
        v-model="dunsNumber"
      />
    </b-field>

    <b-field
      horizontal
      label="NAICS Code"
      label-for="naicsCode"
    >
      <b-input
        id="naicsCode"
        v-model="naicsCode"
      />
    </b-field>

    <b-field
      horizontal
      label="Ticker"
      label-for="ticker"
    >
      <b-input
        id="ticker"
        v-model="ticker"
      />
    </b-field>

    <b-field
      horizontal
      label="Parent Account"
      label-for="parentAccount"
    >
      <div class="columns is-vcentered">
        <div class="column">
          <b-field>
            <b-autocomplete
              id="parentAccount"
              v-model="parent"
              icon-right="search"
              field="name"
              :loading="isLoading"
              :data="parentsSearchResults"
              @typing="getParentsAsyncData"
              @select="(option) => (selectedParent = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.name }}
                    <br>
                    <small>
                      customer number: {{ props.option.externalId }}
                    </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
        </div>

        <div class="column is-narrow">
          <div
            v-if="parent"
            @click="clearParent"
          >
            <b-button
              aria-label="Clear Owner"
              type="is-danger"
              icon-right="close"
            />
          </div>
        </div>
      </div>
    </b-field>

    <b-field
      horizontal
      label="Hedberg Customer #"
      label-for="externalId"
    >
      <b-input
        id="externalId"
        v-model="externalId"
      />
    </b-field>

    <br>

    <div
      v-if="customFieldsList.length > 0"
      class="customFieldsList"
    >
      <div class="content">
        <h3 class="subtitle has-text-primary is-capitalized">
          Custom Fields
        </h3>
      </div>
      <hr class="divider">

      <validation-provider
        v-for="(field, index) in customFieldsList"
        :key="index"
        v-slot="{ errors }"
        :rules="{required: field.isRequired}"
        :name="field.label"
      >
        <b-field
          v-if="field.type === 'TEXT_INPUT'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column">
              <b-input v-model="field.value" />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'CURRENCY'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column">
              <currency-input
                v-model="field.value"
                class="input"
              />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'DROP_DOWN'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column">
              <b-select
                v-model="field.value"
                expanded
              >
                <option
                  v-for="(option, optionIndex) in field.lookups"
                  :key="optionIndex"
                  :value="option.value"
                >
                  {{ option.value }}
                </option>
              </b-select>
            </div>
            <div class="column is-narrow">
              <b-button
                aria-label="Clear Value"
                type="is-danger"
                icon-right="close"
                @click="clearValue(index)"
              />
            </div>
          </div>
        </b-field>
        <b-field
          v-else-if="field.type === 'MULTI_SELECT'"
          horizontal
          :label="field.label"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          :class="{'is-required' : field.isRequired }"
        >
          <div class="columns">
            <div class="column is-half">
              <b-taginput
                v-model="field.value"
                :data="field.lookups.map(i => i.value)"
                autocomplete
                field="value"
                type="is-info"
                :open-on-focus="true"
                placeholder="Select one or multiple"
                @typing="(val) => getCustomFieldFilteredTags(val, field)"
              />
            </div>
          </div>
        </b-field>
      </validation-provider>
    </div>
  </fieldset>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import debounce from '@/utils/debounce';

export default {
  name: 'AccountsForm',

  components: {
    ValidationProvider,
  },

  props: {
    accountTypes: {
      type: Array,
      required: true,
    },

    accountStatuses: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    selectedOwner: null,
    selectedCoOwner: null,
    selectedAccount: null,
    selectedParent: null,
    accountsSearchResults: [],
    parentsSearchResults: [],
    ownerSearchResults: [],
    coOwnerSearchResults: [],
  }),

  computed: {
    ...mapGetters({
      account: 'Accounts/getAccount',
    }),

    defaultAccountType: {
      get() {
        const accTypes = this.accountTypes;
        if (accTypes && accTypes.length > 0) {
          return accTypes.find(type => type.isDefault);
        }
        return {};
      },
    },

    isDefaultType: {
      get() {
        if (this.typeId === 105) {
          return true;
        }
        if (this.defaultAccountType && this.typeId === this.defaultAccountType.id) {
          return true;
        }
        return false;
      },
    },

    percentage: {
      get() {
        return this.$store.state.Accounts.account.owner.percentage;
      },

      set(value) {
        this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', value);
      },
    },

    ticker: {
      get() {
        return this.$store.state.Accounts.account.ticker;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_TICKER', value);
      },
    },

    name: {
      get() {
        return this.$store.state.Accounts.account.name;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_NAME', value);
      },
    },

    owner: {
      get() {
        return this.$store.state.Accounts.account.owner.owner;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_OWNER', value);
      },
    },

    ownerPercentage: {
      get() {
        return this.$store.state.Accounts.account.owner.percentage;
      },

      set(value) {
        this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', value);
      },
    },

    coOwnerId: {
      get() {
        return this.$store.state.Accounts.account.coOwner.ownerId;
      },
    },

    coOwner: {
      get() {
        return this.$store.state.Accounts.account.coOwner.owner;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER', value);
      },
    },

    coOwnerPercentage: {
      get() {
        return this.$store.state.Accounts.account.coOwner.percentage;
      },

      set(value) {
        this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', value);
      },
    },

    statusId: {
      get() {
        return this.$store.state.Accounts.account.statusId;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_STATUS', value);
      },
    },

    typeId: {
      get() {
        return this.$store.state.Accounts.account.typeId;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_TYPE_ID', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Accounts.account.description;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_DESCRIPTION', value);
      },
    },

    dandBCompany: {
      get() {
        return this.$store.state.Accounts.account.dandBCompany;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_D_AND_B_COMPANY', value);
      },
    },

    dunsNumber: {
      get() {
        return this.$store.state.Accounts.account.dunsNumber;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_DUNS_NUMBER', value);
      },
    },

    naicsCode: {
      get() {
        return this.$store.state.Accounts.account.naicsCode;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_NAICS_CODE', value);
      },
    },

    phone: {
      get() {
        return this.$store.state.Accounts.account.phone;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_PHONE', value);
      },
    },

    fax: {
      get() {
        return this.$store.state.Accounts.account.fax;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_FAX', value);
      },
    },

    website: {
      get() {
        return this.$store.state.Accounts.account.website;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_WEBSITE', value);
      },
    },

    parent: {
      get() {
        return this.$store.state.Accounts.account.parent;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_PARENT', value);
      },
    },

    externalId: {
      get() {
        return this.$store.state.Accounts.account.externalId;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_EXTERNAL_ID', value);
      },
    },

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.accountCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_ACC_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Accounts.account.customFields;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_CUSTOM_FIELDS', value);
      },
    },
  },

  watch: {
    customFieldsList: {
      handler(value) {
        if (value && value.length > 0) {
          if (this.$route.name === 'AddAccount') {
            this.$store.commit('Accounts/SET_ACCOUNT_CUSTOM_FIELDS', value.map((item) => (
              {
                customFieldId: item.id,
                value: Array.isArray(item.value)
                  ? item.value.join(',') : item.value || null,
              })));
          } else if (this.$route.name === 'EditAccount' && this.account
          && this.account.customFields.length > 0
          && this.customFields[0].id !== (undefined || null)) {
            this.$store.commit('Accounts/SET_ACCOUNT_CUSTOM_FIELDS', value.map((item, index) => (
              {
                customFieldId: this.customFields[index].customFieldId,
                customFieldEntryId: this.customFields[index].customFieldEntryId,
                value: Array.isArray(item.value)
                  ? item.value.join(',') : item.value || null,
              })));
          }
        }
      },
      deep: true,
    },

    selectedAccount(value) {
      this.$router.push(`/accounts/${value.id}/view`);
    },

    selectedParent(value) {
      return this.$store.commit('Accounts/SET_ACCOUNT_PARENT_ID', value.accountId);
    },

    selectedOwner(value) {
      return this.$store.commit('Accounts/SET_ACCOUNT_OWNER_ID', value.userId);
    },

    selectedCoOwner(value) {
      return this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_ID', value.userId);
    },

    typeId() {
      if (this.isDefaultType && this.$route.name === 'AddAccount') {
        this.$store.commit('Accounts/SET_ACCOUNT_OWNER', localStorage.getItem('full_name'));
        this.$store.commit('Accounts/SET_ACCOUNT_OWNER_ID', localStorage.getItem('user_id'));
      } else if (this.$route.name === 'AddAccount') {
        this.clearOwner();
      }
    },
  },

  methods: {
    mapFields() {
      if (this.customFieldsList && this.customFieldsList.length > 0 && this.$route.name === 'EditAccount') {
        const fieldValues = [...this.customFieldsList].map((item) => {
          const filteredArr = [...this.customFields]
            .filter((i) => i.customFieldId === item.id);
          return ({
            customFieldId: item.id,
            customFieldEntryId: filteredArr.length > 0
              ? [...filteredArr][0].customFieldEntryId : null,
            value: filteredArr.length > 0 ? [...filteredArr][0].value : null,
          });
        });
        this.$store.commit('Accounts/SET_ACCOUNT_CUSTOM_FIELDS', fieldValues);
      }
    },

    getCustomFieldFilteredTags(text, field) {
      this.filteredTags = field.lookups.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    clearOwner() {
      if (this.$route.name === 'EditAccount') {
        this.$store.dispatch(
          'Accounts/deleteAccountOwner',
          {
            accountId: this.$route.params.accountId,
            id: this.$store.state.Accounts.account.owner.id,
            ownerId: this.$store.state.Accounts.account.owner.ownerId,
          },
        );
      }
      this.$store.commit('Accounts/SET_ACCOUNT_OWNER_OWNER_ID', '');
      this.$store.commit('Accounts/SET_ACCOUNT_OWNER', '');
      this.$store.commit('Accounts/SET_ACCOUNT_OWNER_ID', '');
      if (this.coOwnerId) {
        this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', 0);
        this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', 100);
      } else {
        this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', 100);
        this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', 0);
      }
    },

    clearCoOwner() {
      if (this.$route.name === 'EditAccount') {
        this.$store.dispatch(
          'Accounts/deleteAccountOwner',
          {
            accountId: this.$route.params.accountId,
            id: this.$store.state.Accounts.account.coOwner.id,
            ownerId: this.$store.state.Accounts.account.coOwner.ownerId,
          },
        );
      }
      this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_OWNER_ID', '');
      this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER', '');
      this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_ID', '');
      this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', 0);
      this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', 100);
    },

    clearParent() {
      this.$store.commit('Accounts/SET_ACCOUNT_PARENT', '');
      this.$store.commit('Accounts/SET_ACCOUNT_PARENT_ID', '');
    },

    getOwnersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        this.ownerSearchResults = await this.$store.dispatch('Search/searchUsers', token);
      } catch (error) {
        this.ownerSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getCoOwnersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        this.coOwnerSearchResults = await this.$store.dispatch('Search/searchUsers', token);
      } catch (error) {
        this.coOwnerSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getParentsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        this.parentsSearchResults = await this.$store.dispatch('Search/searchAccounts', token);
      } catch (error) {
        this.parentsSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getAccountsAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('DataGrids/fetchAccounts', {
          page: 0,
          size: 50,
          sort: ['createdAt', 'desc'],
          filters: `&${new URLSearchParams({ name: token }).toString()}`,
        });
        this.accountsSearchResults = response;
      } catch (error) {
        this.accountsSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),
  },
};
</script>

<style lang="css" scoped></style>
