import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    isLoading: true,
    isAccountsFormModalActive: false,
    isContactsFormModalActive: false,
  }),

  computed: mapGetters({
    account: 'Accounts/getAccount',
    contact: 'Contacts/getContact',
    leadStatuses: 'Lookups/getLeadStatuses',
    temperatures: 'Lookups/getTemperatures',
    opportunityStages: 'Lookups/getOpportunityStages',
    opportunityTypes: 'Lookups/getOpportunityTypes',
    probabilities: 'Lookups/getProbabilities',
    industries: 'Lookups/getIndustries',
    leadSources: 'Lookups/getLeadSources',
    noOfEmployees: 'Lookups/getNoOfEmployees',
    lead: 'Leads/getLead',
  }),

  async created() {
    try {
      if (this.hasPermissions('read_lookups')) {
        await this.fetchLeadStatuses();
        await this.fetchTemperatures();
        await this.fetchIndustries();
        await this.fetchLeadSources();
        await this.fetchNoOfEmployees();
        await this.fetchOpportunityTypes();
        await this.fetchOpportunityStages();
        await this.fetchProbabilities();
      }
      // if (this.$route.name === 'EditLead') { await this.fetchLead(this.$route.params.leadId); }
    } catch (error) {
      console.error(JSON.stringify(error.message));
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    if (this.$route.name === 'AddLead' && this.$route.query.contactId) {
      this.$store.commit(
        'Leads/SET_LEAD_PRIMARY_CONTACT_ID',
        this.$route.query.contactId,
      );
      this.$store.commit(
        'Leads/SET_LEAD_PRIMARY_CONTACT',
        this.$route.query.contact,
      );
    }

    if (this.$route.name === 'AddLead' && this.$route.query.accountId) {
      this.$store.commit(
        'Leads/SET_LEAD_ACCOUNT_ID',
        this.$route.query.accountId,
      );
      this.$store.commit(
        'Leads/SET_LEAD_ACCOUNT',
        this.$route.query.account,
      );
    }
  },

  methods: {
    ...mapActions({
      fetchLeadStatuses: 'Lookups/fetchLeadStatuses',
      fetchTemperatures: 'Lookups/fetchTemperatures',
      fetchIndustries: 'Lookups/fetchIndustries',
      fetchOpportunityTypes: 'Lookups/fetchOpportunityTypes',
      fetchOpportunityStages: 'Lookups/fetchOpportunityStages',
      fetchProbabilities: 'Lookups/fetchProbabilities',
      fetchLeadSources: 'Lookups/fetchLeadSources',
      fetchNoOfEmployees: 'Lookups/fetchNoOfEmployees',
      fetchLead: 'Leads/fetchLead',
    }),

    /**
     * openContactsFormModal
     *
     * Open ContactsFormModal component & set accountId
     *
     */
    openContactsModal() {
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', this.$store.state.Leads.lead.accountId);
      this.isContactsFormModalActive = true;
    },

    /**
     * convertLeadToOpportunity
     *
     * Convert a lead to an opportunity
     *
     * @param {*} leadId
     */
    async convertToOpportunity(leadId) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('Activities/convertToOpportunity', leadId);
        await this.$router.push(`/opportunities/edit/${response.id}`);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
