<template>
  <!--TODO: Refactor UI form to match the one in components/Contacts/ContactsFormModal -->
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title is-uppercase">
        <b-icon
          icon="account"
          type="is-white"
        />
        <span class="is-padded-left">new contact</span>
      </p>
      <b-button
        icon-right="close"
        type="is-text"
        class="has-text-info"
        @click="$emit('close')"
      />
    </header>

    <div class="card-content">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="false"
        :can-cancel="false"
      />
      <!-- contacts form modal component -->
      <validation-observer
        ref="contactFormModalRef"
        v-slot="{ invalid, handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(createContact)">
          <div class="columns">
            <div class="column is-half">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="first name"
              >
                <b-field
                  label="First Name"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="firstName" />
                </b-field>
              </validation-provider>
            </div>
            <div class="column is-half">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="last name"
              >
                <b-field
                  label="Last Name"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="lastName" />
                </b-field>
              </validation-provider>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required|email"
                name="email"
              >
                <b-field
                  label="Email"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="emailAddress" />
                </b-field>
              </validation-provider>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="phone"
              >
                <b-field
                  label="Phone"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <vue-tel-input v-model="phone" />
                </b-field>
              </validation-provider>
            </div>
          </div>

          <b-field>
            <div class="buttons">
              <b-button
                native-type="submit"
                type="is-success"
                :disabled="invalid"
              >
                Save
              </b-button>
              <b-button
                type="is-info"
                @click="$emit('close')"
              >
                cancel
              </b-button>
            </div>
          </b-field>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ContactsFormModal',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    moduleName: {
      type: String,
      required: true,
    },

    influencerAccountId: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['set-contact'],
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters({ contact: 'Contacts/getContact' }),
    firstName: {
      get() {
        return this.$store.state.Contacts.contact.firstName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_FIRST_NAME', value);
      },
    },
    lastName: {
      get() {
        return this.$store.state.Contacts.contact.lastName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_LAST_NAME', value);
      },
    },
    emailAddress: {
      get() {
        return this.$store.state.Contacts.contact.emailAddress;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_EMAIL_ADDRESS', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.Contacts.contact.phone;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_PHONE', value);
      },
    },
    isActive: {
      get() {
        return this.$store.state.Contacts.contact.isActive;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_IS_ACTIVE', value);
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('Contacts/CLEAR_CONTACT');
  },
  mounted() {
    if (this.$route.query.accountId) {
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', this.$route.query.accountId);
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT', this.$route.query.account);
    }

    if (this.influencerAccountId) {
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', this.influencerAccountId);
    }
  },
  methods: {
    async createContact() {
      this.isLoading = true;
      const payload = { ...this.contact };
      try {
        const response = await this.$store.dispatch('Contacts/createContact', payload);
        this.$emit('set-contact', response);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.$emit('close');
        this.$refs.contactFormModalRef.reset();
      }
    },
  },

};
</script>
<style lang="css" scoped>
</style>
