<template>
  <div>
    <table-header
      :fields="exportFields"
      :name="'contacts'"
      @export-xls="exportContacts"
      @export-pdf="exportPDF(columns, body, 'contacts.pdf')"
      @search="searchContact"
    >
      <template #update-button>
        <b-button
          v-if="isEditing && selectedContactId && validEdit"
          ref="saveEntryButton"
          size="is-small"
          class="mr-4"
          icon-left="content-save"
          type="is-success"
          @click="updateContact"
        >
          save
        </b-button>
        <b-button
          v-if="isEditing && selectedContactId && validEdit"
          ref="cancelEntryButton"
          size="is-small"
          icon-left="close"
          type="is-success"
          @click="cancelUpdate"
        >
          cancel
        </b-button>
      </template>

      <template #open-switch-button>
        <b-field>
          <b-switch v-model="openSwitch">
            <small class="has-text-weight-bold">
              {{ openSwitch ? 'Show Active' : 'Show All' }}
            </small>
          </b-switch>
        </b-field>
      </template>

      <template #re-assign-button>
        <div>
          <b-button
            v-if="(isAdmin) && checkedRows.length > 0"
            class="modal-btn"
            size="is-small"
            @click="openModal"
          >
            Re-assign Owner
          </b-button>
        </div>
      </template>

      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0 || searchClause"
          size="is-small"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isBordered"
                size="is-small"
              >
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isCheckable"
                size="is-small"
              >
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isNarrowed"
                size="is-small"
              >
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStickyHeaders"
                size="is-small"
              >
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isScrollable"
                size="is-small"
              >
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStriped"
                size="is-small"
              >
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
          class="dg-columns"
        >
          <b-button
            slot="trigger"
            icon-left="eye"
            size="is-small"
            icon-right="chevron-down"
          >
            columns
          </b-button>

          <draggable
            v-model="columns"
            group="columns"
            handle=".handle"
            @start="drag = true"
            @end="drag = false"
          >
            <b-dropdown-item
              v-for="column in columns"
              :key="column.id"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                  size="is-small"
                >
                  {{
                    column.label.startsWith('custom')
                      ? getCustomFieldLabel(column.label, 'Contact') : column.label
                  }}
                </b-checkbox>
                <i class="grip-vertical handle" />
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="contactsTable"
      v-click-outside-dg="onClickOutsideTable"
      :mobile-cards="false"
      :loading="loading"
      :data="Array.isArray(contacts) ? contacts : []"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @update:selected="onClickOutsideEditing"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label.startsWith('custom')
          ? getCustomFieldLabel(column.label, 'Contact') : column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template>
            <b-input
              v-show="
                column.searchField === 'createdAt' ||
                  column.searchField === 'updatedAt'
              "
              v-model="props.filters[column.searchField]"
              type="date"
              icon="calendar-month"
              size="is-small"
            />

            <b-input
              v-show="
                column.searchField === 'title' ||
                  column.searchField === 'prefix' ||
                  column.searchField === 'contactName' ||
                  column.searchField === 'email' ||
                  column.searchField === 'phone' ||
                  column.searchField === 'phoneWork' ||
                  column.searchField === 'ownerName' ||
                  column.searchField === 'accounts' ||
                  column.searchField === 'streetOne' ||
                  column.searchField === 'streetTwo' ||
                  column.searchField === 'streetThree' ||
                  column.searchField === 'city' ||
                  column.searchField === 'state' ||
                  column.searchField === 'zipCode' ||
                  column.searchField === 'dateOfBirth' ||
                  column.searchField === 'accountTypes' ||
                  column.searchField === 'industries' ||
                  column.searchField === 'owners' ||
                  column.searchField === 'customField1' ||
                  column.searchField === 'customField2' ||
                  column.searchField === 'customField3' ||
                  column.searchField === 'customField4' ||
                  column.searchField === 'customField5' ||
                  column.searchField === 'customField6' ||
                  column.searchField === 'customField7' ||
                  column.searchField === 'customField8' ||
                  column.searchField === 'customField9' ||
                  column.searchField === 'customField10'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />
          </template>
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div
              v-if="
                hasRole('ROLE_BUSINESS_ADMIN') ||
                  isContactOwner(props.row.contactOwnerId) ||
                  isContactManager(props.row.contactOwnerId)
                  || isTeammate(props.row.teamIds)
              "
              class="buttons"
            >
              <b-tooltip
                type="is-info"
                label="Edit"
                position="is-right"
              >
                <b-button
                  tag="router-link"
                  :to="{
                    name: 'EditContact',
                    params: { contactId: props.row.id },
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="pencil"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Mail"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="email"
                  @click="sendMail(props.row.email, props.row.ownerName)"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Event"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="clock"
                  @click="addActivity(props.row)"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Delete"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="trash-can"
                  @click="deleteContact(props.row.id)"
                />
              </b-tooltip>
            </div>
          </template>

          <template v-else-if="column.field === 'photo'">
            <figure class="image is-32x32">
              <img
                style="width: 32px; height: 32px"
                class="is-rounded"
                :src="props.row.photo ? props.row.photo : defaultImage"
                :alt="props.row.ownerName"
                @click="$router.push(`/contacts/${props.row.id}/view`)"
              >
            </figure>
          </template>

          <template v-else-if="column.field === 'contactName'">
            <router-link
              class="has-text-primary has-text-weight-bold"
              :to="{ name: 'ViewContact', params: { contactId: props.row.id } }"
            >
              {{
                props.row.prefix
                  ? `${props.row.prefix} ${props.row.contactName}`
                  : props.row.contactName
              }}
            </router-link>
          </template>

          <b-tooltip
            v-else
            label="Double-click to edit"
            class="is-info"
          >
            <template v-if="column.field === 'title'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId) ||
                      isContactManager(props.row.contactOwnerId) || isTeammate(props.row.teamIds))
                "
              >
                <b-input
                  v-model="title"
                  size="is-small"
                  type="text"
                />
              </b-field>
              <span
                v-else
                class="has-text-weight-semibold is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row.title ? `${props.row.title}` : '-' }}
              </span>
            </template>

            <template v-else-if="column.field === 'prefix'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId) ||
                      isContactManager(props.row.contactOwnerId) || isTeammate(props.row.teamIds))
                "
              >
                <b-input
                  v-model="prefix"
                  size="is-small"
                  type="text"
                />
              </b-field>
              <span
                v-else
                class="has-text-weight-semibold is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row.prefix ? `${props.row.prefix}` : '-' }}
              </span>
            </template>

            <template v-else-if="column.field === 'email'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId) ||
                      isContactManager(props.row.contactOwnerId) || isTeammate(props.row.teamIds))
                "
              >
                <b-input
                  v-model="emailAddress"
                  size="is-small"
                  type="email"
                />
              </b-field>

              <span
                v-else
                class="has-text-info has-text-weight-semibold is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] | lowercase }}
              </span>
            </template>
            <template v-else-if="column.field === 'phone'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId) ||
                      isContactManager(props.row.contactOwnerId) || isTeammate(props.row.teamIds))
                "
              >
                <vue-tel-input
                  v-model="phone"
                  class="input is-small"
                />
              </b-field>
              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template v-else-if="column.field === 'phoneWork'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId) ||
                      isContactManager(props.row.contactOwnerId) || isTeammate(props.row.teamIds))
                "
              >
                <vue-tel-input
                  v-model="phoneWork"
                  class="input is-small"
                />
              </b-field>
              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template v-else-if="column.field === 'accounts'">
              <template v-if="props.row.accounts">
                <div
                  v-for="(account, accountIndex) in props.row.accounts.split(',')"
                  :key="accountIndex"
                >
                  <b-tag v-if="accountIndex <= 2">
                    {{ account }}
                  </b-tag>
                </div>
                <b-tag v-if="props.row.accounts.split(',').length > 3">
                  + {{ props.row.accounts.split(',').length - 3 }}
                </b-tag>
              </template>
            </template>

            <template v-else-if="column.field === 'ownerName'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId) ||
                      isContactManager(props.row.contactOwnerId) || isTeammate(props.row.teamIds))
                "
              >
                <b-autocomplete
                  v-model="owner"
                  icon-right="search"
                  field="fullName"
                  size="is-small"
                  :data="usersSearchResults"
                  @typing="getUsersAsyncData"
                  @select="(option) => (selectedUser = option)"
                >
                  <template slot-scope="user">
                    <div class="media">
                      <div class="media-content">
                        {{ user.option.fullName.trim() }}
                        <br>
                        <small>
                          email: {{ user.option.email }} <br>
                          Phone: {{ user.option.phoneCell }}
                        </small>
                      </div>
                    </div>
                  </template>

                  <template #empty>
                    No results for {{ owner }}
                  </template>
                </b-autocomplete>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row.ownerName"
                  class="has-text-info has-text-weight-bold is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.ownerName }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'streetOne'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId)
                      || isTeammate(props.row.teamIds))
                "
              >
                <b-input
                  v-model="street1"
                  size="is-small"
                />
              </b-field>

              <span
                v-else
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template v-else-if="column.field === 'streetTwo'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId)
                      || isTeammate(props.row.teamIds))
                "
              >
                <b-input
                  v-model="street2"
                  size="is-small"
                />
              </b-field>

              <span
                v-else
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template v-else-if="column.field === 'streetThree'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId)
                      || isTeammate(props.row.teamIds))
                "
              >
                <b-input
                  v-model="street3"
                  size="is-small"
                />
              </b-field>

              <span
                v-else
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template v-else-if="column.field === 'city'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId)
                      || isTeammate(props.row.teamIds))
                "
              >
                <b-input
                  v-model="city"
                  size="is-small"
                />
              </b-field>

              <span
                v-else
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template v-else-if="column.field === 'state'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId)
                      || isTeammate(props.row.teamIds))
                "
              >
                <b-input
                  v-model="state"
                  size="is-small"
                />
              </b-field>

              <span
                v-else
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template v-else-if="column.field === 'zipCode'">
              <b-field
                v-if="
                  isEditing &&
                    props.row.id === selectedContactId &&
                    (hasRole('ROLE_BUSINESS_ADMIN') ||
                      isContactOwner(props.row.contactOwnerId)
                      || isTeammate(props.row.teamIds))
                "
              >
                {{ props.row[column.field] | date }}
              </b-field>

              <span
                v-else
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row[column.field] }}
              </span>
            </template>

            <template
              v-else-if="
                column.field === 'createdAt' || column.field === 'updatedAt'
              "
            >
              {{ props.row[column.field] | date }}
            </template>

            <template v-else-if="column.field === 'isActive'">
              <b-field
                v-if="
                  isEditing && props.row.id === selectedContactId && (hasRole('ROLE_BUSINESS_ADMIN')
                    || isContactOwner(props.row.contactOwnerId) || isTeammate(props.row.teamIds))
                "
              >
                <b-switch
                  v-model="isActive"
                  size="is-small"
                  type="is-info"
                />
              </b-field>

              <template v-else>
                <span
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row.isActive }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field.startsWith('custom')">
              <div
                v-if="isEditing && props.row.id === selectedContactId
                  && customFieldsList.length > 0 &&
                  (hasRole('ROLE_BUSINESS_ADMIN') ||
                    isContactOwner(props.row.contactOwnerId)
                    || isContactManager(props.row.contactOwnerId)
                    || isTeammate(props.row.teamIds))"
              >
                <validation-provider
                  v-for="(field, fieldIndex) in customFieldsList.filter(
                    (item) => item.label === getCustomFieldLabel(column.label, 'Contact'))"
                  :key="fieldIndex"
                  v-slot="{ errors }"
                  :rules="{required: field.isRequired}"
                  :name="field.label"
                >
                  <b-field
                    v-if="field.type === 'TEXT_INPUT'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <b-input
                      v-model="props.row[column.field]"
                      size="is-small"
                      @input="setNewEditedValues(
                        $event, getCustomFieldLabel(column.label, 'Contact')
                      )"
                    />
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'CURRENCY'"
                    horizontal
                    :label="field.label"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <div class="columns">
                      <div class="column">
                        <currency-input
                          v-model="props.row[column.field]"
                          size="is-small input"
                          expanded
                          @input="setNewEditedValues(
                            $event, getCustomFieldLabel(column.label, 'Contact')
                          )"
                        />
                      </div>
                    </div>
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'DROP_DOWN'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <b-select
                      v-model="props.row[column.field]"
                      size="is-small"
                      expanded
                      @input="setNewEditedValues(
                        $event, getCustomFieldLabel(column.label, 'Contact')
                      )"
                    >
                      <option
                        v-for="(option, optionIndex) in field.lookups"
                        :key="optionIndex"
                        :value="option.value"
                      >
                        {{ option.value }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'MULTI_SELECT'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <div class="columns">
                      <div class="column">
                        <b-taginput
                          v-model="props.row[column.field + 'Arr']"
                          v-multi-select-field="{
                            index: props.index,
                            field: column.field + 'Arr',
                            handler: 'setFormattedMultiSelectValues',
                          }"
                          :data="field.lookups.map(i => i.value)"
                          autocomplete
                          field="value"
                          type="is-info"
                          :open-on-focus="true"
                          placeholder="Select one or multiple"
                          @typing="(val) => getCustomFieldFilteredTags(val, field)"
                          @input="setNewEditedValues(
                            $event, getCustomFieldLabel(column.label, 'Contact')
                          )"
                        />
                      </div>
                    </div>
                  </b-field>
                </validation-provider>
              </div>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  <template
                    v-if="
                      customFieldsList.filter(
                        (item) => item.label === getCustomFieldLabel(column.label, 'Contact')
                      )[0].type === 'CURRENCY'
                    "
                  >
                    {{ props.row[column.field] | currency }}
                  </template>
                  <template v-else>
                    {{ props.row[column.field] }}
                  </template>
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'owners'">
              <div
                v-for="(contact, contactIndex) in props.row.owners.split(',')"
                :key="contactIndex"
              >
                <b-tag>
                  {{ contact }}
                </b-tag>
              </div>
            </template>

            <template v-else>
              {{ props.row[column.field] }}
            </template>
          </b-tooltip>
        </template>
      </b-table-column>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                type="is-info"
                size="is-small"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="contacts" />
      </template>
    </b-table>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="re-assign owners modal"
      aria-modal
    >
      <template>
        <reassign-modal-form
          modal-title="RE-ASSIGN OWNER"
          :route-name="routeName"
          v-bind="formProps"
          @close="closeModal"
        />
      </template>
    </b-modal>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateContact"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import ContactsMixin from '@/mixins/ContactsMixin';
import SearchMixin from '@/mixins/SearchMixin';
import defaultImage from '@/assets/images/avatar.svg';

import {
  TableFooter,
  TableHeader,
  EmptyTable,
  ReassignModalForm,
  InlineEditingSavePrompt,
} from '@/components/Shared';

export default {
  name: 'ContactsTable',

  components: {
    TableFooter,
    TableHeader,
    EmptyTable,
    draggable,
    ReassignModalForm,
    InlineEditingSavePrompt,
  },

  mixins: [ContactsMixin, SearchMixin],

  data: () => ({
    defaultImage,

    total: 0,
    page: 1,
    perPage: 25,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    checkedRows: [],

    rowInEdit: {},

    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    filters: {},

    exportFields: {},

    searchClause: '',

    isEditing: false,

    openSwitch: true,

    inEditingMode: false,

    editRow: {},

    selectedContactId: false,

    isComponentModalActive: false,

    isSavePromptActive: false,

    savePromptOpen: false,

    formProps: {
      dataIDList: [],
    },

    columns: [
      {
        id: '1',
        label: 'Avatar',
        field: 'photo',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: 'photo',
        sticky: false,
        visible: true,
      },
      {
        id: '2',
        label: 'Title',
        field: 'title',
        sortable: true,
        searchable: true,
        searchField: 'title',
        exportField: 'title',
        sticky: false,
        visible: true,
      },
      {
        id: '3',
        label: 'Prefix',
        field: 'prefix',
        sortable: true,
        searchable: true,
        searchField: 'prefix',
        exportField: 'prefix',
        sticky: false,
        visible: true,
      },
      {
        id: '4',
        label: 'Contact Name',
        field: 'contactName',
        sortable: true,
        searchable: true,
        searchField: 'contactName',
        exportField: 'contactName',
        sticky: false,
        visible: true,
      },
      {
        id: '5',
        label: 'Email',
        field: 'email',
        sortable: true,
        searchable: true,
        searchField: 'email',
        exportField: 'email',
        sticky: false,
        visible: true,
      },
      {
        id: '6',
        label: 'Phone',
        field: 'phone',
        sortable: true,
        searchable: true,
        searchField: 'phone',
        exportField: 'phone',
        sticky: false,
        visible: true,
      },
      {
        id: '7',
        label: 'Cell',
        field: 'phoneWork',
        sortable: true,
        searchable: true,
        searchField: 'phoneWork',
        exportField: 'phoneWork',
        sticky: false,
        visible: true,
      },
      {
        id: '8',
        label: 'Owner',
        field: 'ownerName',
        sortable: true,
        searchable: true,
        searchField: 'ownerName',
        exportField: 'ownerName',
        sticky: false,
        visible: true,
      },
      {
        id: '9',
        label: 'Accounts',
        field: 'accounts',
        sortable: true,
        searchable: true,
        searchField: 'accounts',
        exportField: 'accounts',
        sticky: false,
        visible: true,
      },
      {
        id: '10',
        label: 'Street 1',
        field: 'streetOne',
        sortable: true,
        searchable: true,
        searchField: 'streetOne',
        exportField: 'streetOne',
        sticky: false,
        visible: false,
      },
      {
        id: '11',
        label: 'Street 2',
        field: 'streetTwo',
        sortable: true,
        searchable: true,
        searchField: 'streetTwo',
        exportField: 'streetTwo',
        sticky: false,
        visible: false,
      },
      {
        id: '12',
        label: 'Street 3',
        field: 'streetThree',
        sortable: true,
        searchable: true,
        searchField: 'streetThree',
        exportField: 'streetThree',
        sticky: false,
        visible: false,
      },
      {
        id: '13',
        label: 'City',
        field: 'city',
        sortable: true,
        searchable: true,
        searchField: 'city',
        exportField: 'city',
        sticky: false,
        visible: false,
      },
      {
        id: '14',
        label: 'State',
        field: 'state',
        sortable: true,
        searchable: true,
        searchField: 'state',
        exportField: 'state',
        sticky: false,
        visible: false,
      },
      {
        id: '15',
        label: 'Zip',
        field: 'zipCode',
        sortable: true,
        searchable: true,
        searchField: 'zipCode',
        exportField: 'zipCode',
        sticky: false,
        visible: false,
      },
      {
        id: '16',
        label: 'Created On',
        field: 'createdAt',
        sortable: true,
        searchable: true,
        searchField: 'createdAt',
        exportField: 'createdAt',
        sticky: false,
        visible: false,
      },
      {
        id: '17',
        label: 'Modified On',
        field: 'updatedAt',
        sortable: true,
        searchable: true,
        searchField: 'updatedAt',
        exportField: 'updatedAt',
        sticky: false,
        visible: false,
      },
      {
        id: '18',
        label: 'Actions',
        field: 'actions',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
      {
        id: '19',
        label: 'Birthday',
        field: 'dateOfBirth',
        sortable: true,
        searchable: true,
        searchField: 'dateOfBirth',
        exportField: 'dateOfBirth',
        sticky: false,
        visible: false,
      },
      {
        id: '30',
        label: 'Account Type',
        field: 'accountTypes',
        sortable: true,
        searchable: true,
        searchField: 'accountTypes',
        exportField: 'accountTypes',
        sticky: false,
        visible: false,
      },
      {
        id: '31',
        label: 'Industry',
        field: 'industries',
        sortable: true,
        searchable: true,
        searchField: 'industries',
        exportField: 'industries',
        sticky: false,
        visible: false,
      },
      {
        id: '32',
        label: 'Account Owner',
        field: 'owners',
        sortable: true,
        searchable: true,
        searchField: 'owners',
        exportField: 'owners',
        sticky: false,
        visible: false,
      },
      {
        id: '20',
        label: 'custom_field_1',
        field: 'customField1',
        sortable: true,
        searchable: true,
        searchField: 'customField1',
        exportField: 'customField1',
        sticky: false,
        visible: false,
      },
      {
        id: '21',
        label: 'custom_field_2',
        field: 'customField2',
        sortable: true,
        searchable: true,
        searchField: 'customField2',
        exportField: 'customField2',
        sticky: false,
        visible: false,
      },
      {
        id: '22',
        label: 'custom_field_3',
        field: 'customField3',
        sortable: true,
        searchable: true,
        searchField: 'customField3',
        exportField: 'customField3',
        sticky: false,
        visible: false,
      },
      {
        id: '23',
        label: 'custom_field_4',
        field: 'customField4',
        sortable: true,
        searchable: true,
        searchField: 'customField4',
        exportField: 'customField4',
        sticky: false,
        visible: false,
      },
      {
        id: '24',
        label: 'custom_field_5',
        field: 'customField5',
        sortable: true,
        searchable: true,
        searchField: 'customField5',
        exportField: 'customField5',
        sticky: false,
        visible: false,
      },
      {
        id: '25',
        label: 'custom_field_6',
        field: 'customField6',
        sortable: true,
        searchable: true,
        searchField: 'customField6',
        exportField: 'customField6',
        sticky: false,
        visible: false,
      },
      {
        id: '26',
        label: 'custom_field_7',
        field: 'customField7',
        sortable: true,
        searchable: true,
        searchField: 'customField7',
        exportField: 'customField7',
        sticky: false,
        visible: false,
      },
      {
        id: '27',
        label: 'custom_field_8',
        field: 'customField8',
        sortable: true,
        searchable: true,
        searchField: 'customField8',
        exportField: 'customField8',
        sticky: false,
        visible: false,
      },
      {
        id: '28',
        label: 'custom_field_9',
        field: 'customField9',
        sortable: true,
        searchable: true,
        searchField: 'customField9',
        exportField: 'customField9',
        sticky: false,
        visible: false,
      },
      {
        id: '29',
        label: 'custom_field_10',
        field: 'customField10',
        sortable: true,
        searchable: true,
        searchField: 'customField10',
        exportField: 'customField10',
        sticky: false,
        visible: false,
      },
      {
        id: '30',
        label: 'Active',
        field: 'isActive',
        sortable: true,
        searchable: false,
        searchField: 'isActive',
        exportField: 'isActive',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },
  }),

  computed: {
    ...mapGetters({
      loading: 'DataGrids/getLoading',
      // contacts: 'DataGrids/getContacts',
      pageMeta: 'DataGrids/getPageMeta',
      contact: 'Contacts/getContact',
      grid: 'Grids/getGrid',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    validEdit() {
      return this.hasRole('ROLE_BUSINESS_ADMIN')
        || this.isContactOwner(this.editRow.contactOwnerId)
        || this.isContactManager(this.editRow.contactOwnerId)
        || this.isTeammate(this.editRow.teamIds);
    },

    // Check if a user is an admin
    isAdmin() {
      return this.isUserAdmin();
    },

    routeName() {
      return this.$route && this.$route.name ? this.$route.name : '';
    },

    contacts: {
      get() {
        return this.$store.state.DataGrids.contacts;
      },

      set(value) {
        return this.$store.commit('DataGrids/SET_CONTACTS_CONTENT', value);
      },
    },

    isActive: {
      get() {
        return this.$store.state.Contacts.contact.isActive;
      },

      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_IS_ACTIVE', value);
      },
    },

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.contactCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_CONTACT_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Contacts.contact.customFields;
      },

      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_CUSTOM_FIELDS', value);
      },
    },
  },

  watch: {
    rowInEdit: {
      async handler(value) {
        await this.$store.commit('Contacts/SET_CONTACT_OBJECT', value);
      },
    },

    perPage: {
      handler() {
        this.fetchContacts();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },

    openSwitch: {
      handler() {
        this.fetchContacts();
      },

      deep: true,
    },

    selectedUser(value) {
      return this.$store.commit('Contacts/SET_CONTACT_OWNER_ID', value.userId);
    },

    isComponentModalActive: {
      handler(value) {
        if (!value) {
          this.fetchContacts();
        }
      },
      deep: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchGrid', {
        gridName: 'contacts',
        organizationId,
      });

      await this.$store.dispatch('Settings/fetchCustomFields', 'Contact');

      const sortedColumnList = [...this.columns]
        .map((newItem) => {
          if (newItem.label.startsWith('custom')) {
            return ({
              ...newItem,
              visible: true,
            });
          }
          return newItem;
        })
        .filter((item) => (item.label.startsWith('custom')
          ? this.getCustomFieldLabel(item.label, 'Contact') !== '' : true));
      this.columns = sortedColumnList;

      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          customizations: this.customizations,
          name: 'contacts',
          filters: this.filters,
        });
      }
      await this.mapSavedFilters();
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    if (Object.keys(this.$route.query).length > 0 && this.$route.path.includes('/contacts')) {
      const payload = { ...this.$route.query };
      delete payload?.activeTab;
      this.filters = payload;
    }

    if (this.grid.columns && (!this.grid.filters || Object.keys(this.grid.filters).length === 0)) {
      this.fetchContacts();
    }
  },

  beforeUpdate() {
    if (localStorage.getItem('access_token') && this.$route.path.includes('/contacts')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'contacts',
        customizations: this.customizations,
        filters: this.filters,
      });
    }
  },

  methods: {
    mapSavedFilters() {
      if (this.grid.filters) {
        this.$refs.contactsTable.filters = { ...this.grid.filters };
      }
      return '';
    },

    async setMultiSelectFields() {
      if (this.selectedContactId && this.isEditing) {
        const customFields = [...this.customFields];
        const newCustomFields = customFields.map((it) => ({
          ...it,
          value: Array.isArray(it.value)
            ? it.value.join(',') : it.value,
        }));

        const newValue = {
          ...this.contact,
          customFields: newCustomFields,
        };

        await this.setRowInEdit(newValue);
      }
    },

    async setFormattedMultiSelectValues(index, field) {
      try {
        if (this.selectedContactId && this.isEditing) {
          this.contacts = this.contacts.map((item, ind) => {
            if (index === ind && this.inEditingMode) {
              const newItem = { ...item };
              if (item[field]) {
                newItem[field] = item[field] && Array.isArray(item[field]) ? item[field] : item[field].split(' ');
              }
              return newItem;
            }
            return item;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async setNewEditedValues(event, label) {
      try {
        if (this.selectedContactId && this.isEditing) {
          const customFields = [...this.customFields];
          const findField = customFields.findIndex((obj) => obj.label === label);
          const newObj = customFields[findField];
          customFields.splice(findField, 1, {
            ...newObj,
            value: Array.isArray(event)
              ? event.join(',') : event,
          });

          const newValue = {
            ...this.contact,
            customFields,
          };

          await this.setRowInEdit(newValue);
        }
      } catch (error) {
        console.error(error);
      }
    },

    getCustomFieldFilteredTags(text, field) {
      this.filteredTags = field.lookups.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label.startsWith('custom')
          ? this.getCustomFieldLabel(el.label, 'Contact') : el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    configurationValidator() {
      const hasBirthday = this.grid.columns.find(
        (el) => el.label === 'Birthday',
      );
      if (!hasBirthday) {
        this.grid.columns.push({
          id: '18',
          label: 'Birthday',
          field: 'dateOfBirth',
          sortable: true,
          searchable: true,
          searchField: 'dateOfBirth',
          exportField: 'dateOfBirth',
          sticky: false,
          visible: false,
        });
      }
      const defaultConfiguration = this.columns.map((el) => el.label)
        .sort();
      const userConfiguration = this.grid.columns.map((el) => el.label)
        .sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedContactId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    /**
     * setGrid
     *
     */
    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );

      const getVisibility = (fieldName) => {
        const column = this.grid.columns.find((el) => el.label === fieldName);
        if (column) {
          return column.visible;
        }
        return true;
      };

      const newColumns = this.columns.map((el) => ({
        ...el,
        visible: getVisibility(el.label),
      }));

      const customFieldExists = (fieldName) => {
        const data = JSON.parse(localStorage.getItem('custom_fields'));
        const customFields = [...data];
        return customFields.includes((el) => el.code === fieldName && el.objectName === 'Contact');
      };

      const hasCustomField = (fieldName) => newColumns.includes(
        (el) => el.label === fieldName,
      );

      // check for customFields available & append
      if (!hasCustomField('custom_field_1') && customFieldExists('custom_field_1')) {
        newColumns.push(
          {
            id: '20',
            label: 'custom_field_1',
            field: 'customField1',
            sortable: true,
            searchable: true,
            searchField: 'customField1',
            exportField: 'customField1',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_2') && customFieldExists('custom_field_2')) {
        newColumns.push(
          {
            id: '21',
            label: 'custom_field_2',
            field: 'customField2',
            sortable: true,
            searchable: true,
            searchField: 'customField2',
            exportField: 'customField2',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_3') && customFieldExists('custom_field_3')) {
        newColumns.push(
          {
            id: '22',
            label: 'custom_field_3',
            field: 'customField3',
            sortable: true,
            searchable: true,
            searchField: 'customField3',
            exportField: 'customField3',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_4') && customFieldExists('custom_field_4')) {
        newColumns.push(
          {
            id: '23',
            label: 'custom_field_4',
            field: 'customField4',
            sortable: true,
            searchable: true,
            searchField: 'customField4',
            exportField: 'customField4',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_5') && customFieldExists('custom_field_5')) {
        newColumns.push(
          {
            id: '24',
            label: 'custom_field_5',
            field: 'customField5',
            sortable: true,
            searchable: true,
            searchField: 'customField5',
            exportField: 'customField5',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_6') && customFieldExists('custom_field_6')) {
        newColumns.push(
          {
            id: '25',
            label: 'custom_field_6',
            field: 'customField6',
            sortable: true,
            searchable: true,
            searchField: 'customField6',
            exportField: 'customField6',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_7') && customFieldExists('custom_field_7')) {
        newColumns.push(
          {
            id: '26',
            label: 'custom_field_7',
            field: 'customField7',
            sortable: true,
            searchable: true,
            searchField: 'customField7',
            exportField: 'customField7',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_8') && customFieldExists('custom_field_8')) {
        newColumns.push(
          {
            id: '27',
            label: 'custom_field_8',
            field: 'customField8',
            sortable: true,
            searchable: true,
            searchField: 'customField8',
            exportField: 'customField8',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_9') && customFieldExists('custom_field_9')) {
        newColumns.push(
          {
            id: '28',
            label: 'custom_field_9',
            field: 'customField9',
            sortable: true,
            searchable: true,
            searchField: 'customField9',
            exportField: 'customField9',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_10') && customFieldExists('custom_field_10')) {
        newColumns.push(
          {
            id: '29',
            label: 'custom_field_10',
            field: 'customField10',
            sortable: true,
            searchable: true,
            searchField: 'customField10',
            exportField: 'customField10',
            sticky: false,
            visible: true,
          },
        );
      } else if (hasCustomField('custom_field_1') && !customFieldExists('custom_field_1')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_1');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_2') && !customFieldExists('custom_field_2')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_2');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_3') && !customFieldExists('custom_field_3')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_3');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_4') && !customFieldExists('custom_field_4')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_4');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_5') && !customFieldExists('custom_field_5')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_5');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_6') && !customFieldExists('custom_field_6')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_6');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_7') && !customFieldExists('custom_field_7')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_7');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_8') && !customFieldExists('custom_field_8')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_8');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_9') && !customFieldExists('custom_field_9')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_9');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_10') && !customFieldExists('custom_field_10')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_10');
        newColumns.splice(index, 1);
      }
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
      this.filters = this.grid.filters || {};
    },

    /**
     * sendMail
     *
     */
    sendMail(email, subject) {
      window.location.assign(`mailto:${email}?subject=${subject}`);
    },

    addActivity(value) {
      this.$router.push(
        `/activities/add?contactId=${value.id}&contact=${value.contactName}`,
      );
    },

    async fetchContacts() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters)
          .forEach(
            (key) => filters[key] === '' && delete filters[key],
          );
        if (Object.keys(filters)
          .includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt)
            .toISOString()
            .substring(0, 10);
        }
        if (Object.keys(filters)
          .includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt)
            .toISOString()
            .substring(0, 10);
        }
      }

      if (this.openSwitch) {
        // TODO---  change isActive
        filters = {
          ...filters,
          isActive: true,
        };
      }

      if (this.$route.name === 'ViewAccount') {
        filters = {
          ...filters,
          accountIds: this.$route.params.accountId,
        };
      } else if (this.$route.name === 'ViewLead') {
        filters = {
          ...filters,
          opportunityId: this.$route.params.leadId,
        };
      } else if (this.$route.name === 'ViewOpportunity') {
        filters = {
          ...filters,
          opportunityId: this.$route.params.opportunityId,
        };
      } else if (this.$route.name === 'ViewAccount') {
        filters = {
          ...filters,
          accountId: this.$route.params.accountId,
        };
      }

      try {
        await this.$store.dispatch('DataGrids/fetchContacts', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters:
            filters && Object.keys(filters).length > 0
              ? `&${new URLSearchParams(filters).toString()}`
              : '',
        });

        // this.total = this.contacts.totalElements;
      } catch (error) {
        console.error(error);
      } finally {
        await this.$store.dispatch('DataGrids/fetchContactsPageMeta', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters: filters && Object.keys(filters).length > 0
            ? `&${new URLSearchParams(filters).toString()}` : '',
        });
        this.total = this.pageMeta.totalElements;
      }
    },

    async exportContacts() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters)
          .forEach(
            (key) => filters[key] === '' && delete filters[key],
          );
        if (Object.keys(filters)
          .includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt)
            .toISOString()
            .substring(0, 10);
        }
        if (Object.keys(filters)
          .includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt)
            .toISOString()
            .substring(0, 10);
        }
      }

      if (this.openSwitch) {
        // TODO---  change isActive
        filters = {
          ...filters,
          isActive: true,
        };
      }

      if (this.$route.name === 'ViewAccount') {
        filters = {
          ...filters,
          accountIds: this.$route.params.accountId,
        };
      } else if (this.$route.name === 'ViewLead') {
        filters = {
          ...filters,
          opportunityId: this.$route.params.leadId,
        };
      } else if (this.$route.name === 'ViewOpportunity') {
        filters = {
          ...filters,
          opportunityId: this.$route.params.opportunityId,
        };
      } else if (this.$route.name === 'ViewAccount') {
        filters = {
          ...filters,
          accountId: this.$route.params.accountId,
        };
      }

      try {
        await this.$store.dispatch('Exports/fetchContacts', {
          sort: [this.sortField, this.sortOrder],
          filters:
            filters && Object.keys(filters).length > 0
              ? `&${new URLSearchParams(filters).toString()}`
              : '',
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @params { Number } - next page number
     */
    onPageChange(page) {
      this.page = page;
      this.fetchContacts();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchContacts();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = filters;
      this.fetchContacts();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.contactsTable.resetMultiSorting();
      this.$refs.contactsTable.filters = {};
      this.searchClause = '';
      this.filters = {};
      this.fetchContacts();
    },

    /**
     * onEdit
     *
     * @param {object} contact
     */
    async onEdit(contact, props) {
      this.loading = true;
      try {
        if ((this.hasRole('ROLE_BUSINESS_ADMIN')
            || this.isContactOwner(contact.contactOwnerId))
          || this.isContactManager(contact.contactOwnerId
            || this.isTeammate(contact.teamIds))) {
          this.selectedContactId = contact.id;
          this.isEditing = true;
          this.editRow = contact;
          await this.$store.dispatch('Contacts/fetchContact', contact.id);

          const newValue = {
            ...this.contact,
            customFields: this.customFieldsList.map((item) => {
              const sortedArr = this.contact.customFields
                .filter((newItem) => newItem.customFieldId === item.id);
              if (sortedArr.length > 0) {
                return ({
                  customFieldId: item.id,
                  customFieldEntryId: sortedArr.length > 0
                    ? sortedArr[0].customFieldEntryId : null,
                  label: item.label,
                  value: sortedArr[0].value?.split(',').length > 1
                    ? sortedArr[0].value.split(',') : sortedArr[0].value,
                });
              }
              return (
                {
                  customFieldId: item.id,
                  customFieldEntryId: null,
                  label: item.label,
                  value: null,
                }
              );
            }),
          };
          await this.setRowInEdit(newValue);

          this.contacts = this.contacts.map((item, ind) => {
            if (props.index === ind) {
              const entries = Object.entries(item);
              entries.forEach((i) => {
                if (i[0].startsWith('customField') && i[1] !== null) {
                  entries.push([`${i[0]}Arr`, i[1].split(',').length > 1 ? i[1].split(',') : i[1]]);
                }
              });

              return {
                ...Object.fromEntries(entries),
              };
            }
            return item;
          });

          this.setMultiSelectFields();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.inEditingMode = true;
        this.$emit('finished-mapping');
      }
    },

    setRowInEdit(value) {
      this.rowInEdit = value;
      return this.rowInEdit;
    },

    /**
     * searchContact
     *
     */
    searchContact(value) {
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchContacts();
      }, 800);
    },

    /**
     * updateContact
     *
     */
    async updateContact() {
      try {
        const payload = { ...this.contact };
        if (payload.contactPhoto) {
          payload.contactPhoto = payload.contactPhoto.startsWith('http')
            ? null
            : payload.contactPhoto;
        }
        if (payload.title === null) {
          payload.title = '';
        }

        if (payload.prefix === null) {
          payload.prefix = '';
        }

        if (payload.photo) {
          delete payload.photo;
        }
        await this.$store.dispatch('Contacts/updateContact', payload);
        await this.fetchContacts();
        this.isEditing = false;
        this.selectedContactId = '';
        this.$store.commit('Contacts/CLEAR_CONTACT');
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * cancelUpdate
     *
     */
    async cancelUpdate() {
      try {
        this.isEditing = false;
        this.selectedContactId = '';
        this.$store.commit('Contacts/CLEAR_CONTACT');
        await this.fetchContacts();
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * deleteContact
     *
     * @param {Number} contactId
     */
    deleteContact(contactId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting contact',
        message:
          'Are you sure you want to <b>delete</b> your contact? This action cannot be undone.',
        confirmText: 'Delete Contact',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Contacts/deleteContact', contactId);
            await this.fetchContacts();
          } catch (error) {
            console.error(error);
          }
        },
      });
    },

    openModal() {
      this.isComponentModalActive = true;
      const checkedContacts = this.checkedRows.length > 0 ? this.checkedRows : [];
      const contactIDList = checkedContacts.map((contact) => contact.id);
      this.formProps.dataIDList = contactIDList;
    },

    async closeModal() {
      this.isComponentModalActive = false;

      /* START: FORCE PAGE RELOAD ON CLOSING MODAL */
      await this.fetchContacts();
      setTimeout(async () => {
        await this.fetchContacts();
      }, 300);
      /* END: FORCE PAGE RELOAD ON CLOSING MODAL */
      this.checkedRows = [];
    },
  },
};
</script>

<style lang="css" scoped>
</style>
